<template>
	<section>
		<div v-if="isPopUpOpinionsOpen" class="overlay"></div>
		<div class="containerAbout">
			<div class="wrapper wrapper__about">
				<ArrowSvg v-if="!isMobile" class="arrowSvg" @click="navigateToSection('SectionAbout')"></ArrowSvg>
				<h2 id="HeaderSectionAbout">Kim jesteśmy?</h2>
				<p>
					Zajmujemy się generalną obsługą inwestycji budowlanych, pomagamy dobrać technologię w oparciu o budżet żeby
					zmaksymalizować oczekiwany efekt. Potrafimy kreatywnie podejść do tematu budowania/remontowania, doradzić w
					celu uzyskania długofalowego efektu, porozbijać budowę na etapy, biorąc pod uwagę wszystkie aspekty jak
					możliwości finansowe, komfort inwestorów, długoterminowe oszczędności. Potrafimy także realnie ocenić i
					przedstawić ryzyko doraźnych rozwiązań - jesteśmy świadomi, że czasem one też są potrzebne.
				</p>
			</div>
			<div class="wrapper wrapper__opinions">
				<ul class="opinions__ul">
					<li v-for="opinion in randomOpinions" :key="opinion.name" class="opinion__li">
						<OpinionItem :opinionData="opinion" :nameParent="'Section'"></OpinionItem>
					</li>
				</ul>
			</div>
		</div>
		<transition name="transition__popUp">
			<div v-if="isPopUpOpinionsOpen" class="popUp">
				<CrossSvg class="crossSvg" @click="togglePopUp"></CrossSvg>
				<ul class="popUp__ul">
					<li v-for="opinion in opinionsArr" :key="opinion.name" class="popUp__li">
						<OpinionItem :opinionData="opinion" :nameParent="'PopUp'"></OpinionItem>
					</li>
				</ul>
			</div>
		</transition>
	</section>
</template>
<script>
import { opinionsArr } from './opinionsArr.js'
import OpinionItem from './OpinionItem.vue'
import CrossSvg from '@/assets/additions/CrossSvg.vue'
import ArrowSvg from '@/assets/components/sectionAbout/ArrowSvg.vue'
export default {
	name: 'SectionAbout',
	components: { OpinionItem, CrossSvg, ArrowSvg },
	data() {
		return {
			opinionsArr,
			randomOpinions: [],
		}
	},
	mounted() {
		this.chooseRandomOpinions()
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		isPopUpOpinionsOpen() {
			return this.$store.getters.isPopUpOpinionsOpen
		},
	},
	methods: {
		chooseRandomOpinions() {
			const shuffledOpinions = [...this.opinionsArr]
			shuffledOpinions.sort(() => Math.random() - 0.5)
			this.randomOpinions = shuffledOpinions.slice(0, 3)
		},
		navigateToSection(sectionName) {
			this.$store.commit('navigateToSection', sectionName)
		},
		togglePopUp() {
			this.$store.commit('togglePopUpOpinions')
		},
	},
}
</script>

<style lang="scss" scoped>
section {
	@include basic-section(none);
	padding-top: 60px;
}

.overlay {
	@include overlay;
}
.wrapper {
	box-shadow: 5px 14px 38px 0px rgba(0, 0, 0, 0.25);
	position: relative;

	&__about {
		padding-top: 50px;
		padding-bottom: 80px;
		background: linear-gradient(to right bottom, #15203b, #2b3755);
		border-radius: 0 111px 111px 0;
		z-index: 1;

		h2,
		p {
			@include center-margin;
			width: 340px;
		}
		h2 {
			color: $background-color-primary;
			font-weight: 400;
		}

		p {
			margin-top: 50px;
			color: $text-color-secondary;
			font-weight: 300;
		}
	}

	&__opinions {
		padding-top: 80px;
		padding-bottom: 80px;
		background: linear-gradient(to right top, #2b3655, #15203b);
		border-radius: 0 111px 0 0;

		.opinions__ul {
			@include center-margin;
			max-width: fit-content;

			.opinion__li {
				width: 320px;
				margin-bottom: 40px;
			}

			.opinion__li::after {
				margin-top: 20px;
				@include position-pseudoClass(none, none, none, none, 1px, 60%, none);
				background: linear-gradient(to left, rgba(197, 158, 75, 1) 10%, rgba(197, 160, 77, 0) 100%);
			}

			.opinion__li:last-child::after {
				content: none;
			}
			.opinion__li:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.transition__popUp {
	@include slideInOutAnimation(0.2s, ease-out, cubic-bezier(1, 0.5, 0.8, 1));
}
.popUp {
	@include popUp-basic;
	width: 400px;
	background-color: $background-color-primary;

	.crossSvg {
		@include position-absolute(15px, 15px, none, none);
		cursor: pointer;
	}
	.popUp__ul {
		height: calc(100% - 50px);
		margin-top: 50px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $text-color-primary;
			border-radius: 6px;
		}

		.popUp__li {
			margin-bottom: 15px;
		}
	}
}
@media (max-width: 1023px) {
	.wrapper {
		@include center-margin;
		width: 390px;
		.wrapper__opinions {
			margin-top: -40px;
		}
	}
}
@media (min-width: 1024px) {
	section {
		padding-top: 70px;
	}
	.containerAbout {
		@include center-margin;
		width: 930px;
		position: relative;

		.wrapper {
			&__about {
				@include position-absolute(-30px, none, none, none);
				width: 460px;
				padding-bottom: 140px;
				border-radius: 120px;
				.arrowSvg {
					@include center-margin;
					display: block;
					cursor: pointer;
				}

				h2,
				p {
					width: 370px;
				}
				h2 {
					margin-top: 70px;
					font-size: 24px;
				}

				p {
					margin-top: 20px;
					font-size: 16px;
				}
			}

			&__opinions {
				margin-left: auto;
				width: 690px;
				border-radius: 0px 120px 120px 120px;

				.opinions__ul {
					transform: translateX(100px);
					.opinion__li {
						width: 380px;
					}

					.opinion__li::after {
						left: 50px;
					}
				}
			}
		}
	}

	.popUp {
		@include flex(none, center, center);
		width: 900px;
		max-height: 450px;
		.popUp__ul {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}

		.popUp__li {
			margin: 0 20px 20px 0;
		}
	}
}

@media (min-width: 1500px) {
	.containerAbout {
		width: 1220px;
		transform: translateX(-10px);
		.wrapper {
			&__about {
				top: -190px;
				left: -50px;
				width: 575px;
				padding-bottom: 180px;
				border-radius: 167px;
				p,
				h2 {
					width: 400px;
					margin-left: 110px;
				}
				p {
					margin-top: 40px;
					line-height: 1.3;
				}
			}

			&__opinions {
				width: 1290px;
				padding-top: 60px;
				border-radius: 150px;

				.opinions__ul {
					transform: translateX(180px);
					.opinion__li {
						width: 520px;
					}
					.opinion__li::after {
						left: 20px;
						width: 80%;
					}
				}
			}
		}
	}
	.popUp {
		width: 1140px;
		max-height: 600px;
	}
}
</style>
