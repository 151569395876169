<template>
	<div>
		<svg width="40" height="40" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_962_81)">
				<path
					d="M82.9201 73.4398C82.9201 75.0598 81.6101 76.3698 79.9901 76.3698C78.3701 76.3698 77.0601 75.0598 77.0601 73.4398C77.0601 71.8198 78.3701 70.5098 79.9901 70.5098C81.6101 70.5098 82.9201 71.8198 82.9201 73.4398Z"
					fill="#232E51"
				/>
				<path
					d="M3.43 100H97.57C99.19 100 100.5 98.69 100.5 97.07C100.5 95.45 99.19 94.14 97.57 94.14H94.64V80.51L95.5 81.37C96.64 82.51 98.5 82.51 99.64 81.37C100.78 80.23 100.78 78.37 99.64 77.23L82.06 59.65C80.92 58.51 79.06 58.51 77.92 59.65L60.34 77.23C59.2 78.37 59.2 80.23 60.34 81.37C61.48 82.51 63.34 82.51 64.48 81.37L65.34 80.51V94.14H41.71V35.35H44.64C46.26 35.35 47.57 34.04 47.57 32.42V29.49H82.92V35.35H74.13C72.51 35.35 71.2 36.66 71.2 38.28V50C71.2 51.62 72.51 52.93 74.13 52.93H97.57C99.19 52.93 100.5 51.62 100.5 50V38.28C100.5 36.66 99.19 35.35 97.57 35.35H88.78V29.49H97.57C99.19 29.49 100.5 28.18 100.5 26.56V14.84C100.5 13.22 99.19 11.91 97.57 11.91H41.71V2.93C41.71 1.31 40.4 0 38.78 0H27.06C25.44 0 24.13 1.31 24.13 2.93V11.91H9.29C8.18 11.91 7.17 12.54 6.67 13.53L0.81 25.25C0.36 26.16 0.4 27.24 0.94 28.1C1.47 28.96 2.42 29.49 3.43 29.49H18.27V32.42C18.27 34.04 19.58 35.35 21.2 35.35H24.13V58.79H3.43C1.81 58.79 0.5 60.1 0.5 61.72V97.07C0.5 98.69 1.81 100 3.43 100ZM94.64 47.07H77.06V41.21H94.64V47.07ZM6.36 88.09H24.13V94.14H6.36V88.09ZM34.64 79.3L29.99 83.95V74.66L34.64 79.31V79.3ZM31.21 67.58L35.86 62.93V72.22L31.21 67.57V67.58ZM29.99 60.51V51.22L34.64 55.87L29.99 60.52V60.51ZM24.13 82.23H6.36V76.37H24.13V82.23ZM71.2 74.65L79.99 65.86L88.78 74.65V94.14H82.92V85.16C82.92 83.54 81.61 82.23 79.99 82.23C78.37 82.23 77.06 83.54 77.06 85.16V94.14H71.2V74.65ZM29.99 94.14V92.23L35.85 86.37V94.14H29.99ZM41.71 29.49H24.13V17.77H41.71V29.49ZM47.57 17.77H61.2L55.34 23.63H47.57V17.77ZM79.99 22.42L75.34 17.77H84.63L79.98 22.42H79.99ZM72.92 23.63H63.63L68.28 18.98L72.93 23.63H72.92ZM94.64 23.63H87.06L92.92 17.77H94.64V23.63ZM29.99 5.86H35.85V11.91H29.99V5.86ZM8.17 23.63L11.1 17.77H18.27V23.63H8.17ZM35.85 35.35V48.79L29.99 42.93V35.35H35.85ZM24.13 64.65V70.51H6.36V64.65H24.13Z"
					fill="#232E51"
				/>
			</g>
			<defs>
				<clipPath id="clip0_962_81">
					<rect width="100" height="100" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'ConsultingSvg',
	computed: {},
}
</script>
