<template>
	<section>
		<div class="sectionOffer">
			<div class="sectionOffer__header">
				<header>
					<h2 id="HeaderSectionOffer">Nasza oferta</h2>
				</header>
			</div>
			<article class="sectionOffer__offers">
				<ul class="offers__ul">
					<li v-for="offer in offersArr" :key="offer.name" class="offer__li">
						<OfferItem :offerData="offer"></OfferItem>
					</li>
				</ul>
			</article>
		</div>
	</section>
</template>

<script>
import { offersArr } from './offersArr.js'
import OfferItem from './OfferItem.vue'
export default {
	name: 'SectionOffer',
	components: {
		OfferItem,
	},
	data() {
		return {
			offersArr,
			openedOfferData: Object,
		}
	},
}
</script>
<style lang="scss" scoped>
section {
	@include basic-section(none);
	padding-top: 60px;
	padding-bottom: 40px;
}

.sectionOffer {
	@include center-margin;
	width: 340px;
	position: relative;

	.sectionOffer__header {
		color: $text-color-primary;

		h2 {
			font-weight: 500;
		}
	}
	.sectionOffer__offers {
		margin-top: 50px;

		.offers__ul {
			@include flex(row, center, none);
			flex-wrap: wrap;

			.offer__li {
				margin-bottom: 50px;
				cursor: auto;
			}
		}
	}
}

@media (max-width: 1024px) {
}
@media (min-width: 1024px) {
	section {
		padding-top: 120px;
	}
	.sectionOffer {
		width: 930px;

		.sectionOffer__offers {
			margin-top: 60px;
		}
	}
}
@media (min-width: 1500px) {
	section {
		padding-bottom: 170px;
	}
	.sectionOffer {
		width: 1300px;

		.sectionOffer__header {
			p {
				margin-top: 30px;
			}
		}

		.sectionOffer__offers {
			margin-top: 50px;
			.offers__ul > li:nth-child(odd) {
				margin-right: 60px;
			}
			.offers__ul {
				.offer__li {
					margin-bottom: 70px;
				}

				.offer__li:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
