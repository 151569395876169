import { createStore } from 'vuex'

const store = createStore({
	state() {
		return {
			navbarLinks: [
				{ name: 'SectionHome', pl: 'HOME', idHeader: 'HeaderSectionHome' },
				{ name: 'SectionAbout', pl: 'O NAS', idHeader: 'HeaderSectionAbout' },
				{ name: 'SectionOffer', pl: 'OFERTA', idHeader: 'HeaderSectionOffer' },
				{ name: 'SectionGallery', pl: 'GALERIA', idHeader: 'HeaderSectionGallery' },
				{ name: 'SectionContact', pl: 'KONTAKT', idHeader: 'HeaderSectionContact' },
			],
			isMobile: false,
			isTablet: false,
			isDesktop: false,
			currentSection: 'SectionHome',
			isNavigating: false,
			isPopUpNavOpen: false,
			isPopUpOfferOpen: false,
			isPopUpOpinionsOpen: false,
			isPopUpGalleryOpen: false,
		}
	},
	mutations: {
		setDevice(state, width) {
			state.isMobile = width <= 1023
			state.isTablet = width >= 1024 && width < 1500
			state.isDesktop = width >= 1500
		},
		setCurrentSection(state, sectionName) {
			state.currentSection = sectionName
		},
		navigateToSection(state, sectionName) {
			if (state.isNavigating) {
				return
			}
			if (state.isPopUpNavOpen) {
				store.commit('togglePopUpNav')
			}
			state.isNavigating = true
			const section = state.navbarLinks.find(item => item.name === sectionName)
			const sectionElementId = section && section.idHeader

			if (!sectionElementId) {
				return
			}
			const heightMap = sectionElementId === 'HeaderSectionHome' ? 0 : { isMobile: 100, isTablet: 125, isDesktop: 150 }

			const sectionElement = document.getElementById(sectionElementId)

			const height = heightMap[this.getters.isMobile ? 'isMobile' : this.getters.isTablet ? 'isTablet' : 'isDesktop']

			const currentPosition = window.scrollY
			let targetPosition = 0

			if (sectionElementId !== 'HeaderSectionHome') {
				targetPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset - height
			}

			const startTime = performance.now()
			const duration = 1000

			function scrollAnimation(currentTime) {
				const elapsedTime = currentTime - startTime
				const progress = Math.min(elapsedTime / duration, 1)
				const ease = progress => 0.5 * (1 - Math.cos(Math.PI * progress))

				window.scrollTo({
					top: currentPosition + (targetPosition - currentPosition) * ease(progress),
					behavior: 'auto',
				})

				if (elapsedTime < duration) {
					requestAnimationFrame(scrollAnimation)
				} else {
					state.currentSection = sectionName
					state.isNavigating = false
				}
			}

			requestAnimationFrame(scrollAnimation)
		},

		togglePopUpNav(state) {
			state.isPopUpNavOpen = !state.isPopUpNavOpen
		},
		togglePopUpOffer(state) {
			state.isPopUpOfferOpen = !state.isPopUpOfferOpen
			handleOverflow(state)
		},
		togglePopUpOpinions(state) {
			state.isPopUpOpinionsOpen = !state.isPopUpOpinionsOpen
			handleOverflow(state)
		},
		togglePopUpGallery(state) {
			state.isPopUpGalleryOpen = !state.isPopUpGalleryOpen
			handleOverflow(state)
		},
		closePopUpNavOnChangeDevice(state, { getters, commit }, isMobileAnOldDevice) {
			const newDevice = getters.isTablet
			if (!isMobileAnOldDevice && newDevice && getters.isPopUpNavOpen) {
				commit('togglePopUpNav')
			}
		},
	},
	actions: {
		handleScroll({ commit, getters, state }) {
			if (!getters.isNavigating) {
				const visibleSection = getVisibleSection(state.navbarLinks)
				visibleSection && commit('setCurrentSection', visibleSection)
			}
		},

		handleResize({ commit, getters }) {
			const width = window.innerWidth
			const isMobileAnOldDevice = getters.isMobile
			commit('setDevice', width)
			commit('closePopUpNavOnChangeDevice', { getters, commit }, isMobileAnOldDevice)
		},
	},
	getters: {
		isMobile: state => state.isMobile,
		isTablet: state => state.isTablet,
		isDesktop: state => state.isDesktop,
		getCurrentSection: state => state.currentSection,
		isNavigating: state => state.isNavigating,
		isPopUpNavOpen: state => state.isPopUpNavOpen,
		isPopUpOfferOpen: state => state.isPopUpOfferOpen,
		isPopUpOpinionsOpen: state => state.isPopUpOpinionsOpen,
		isPopUpGalleryOpen: state => state.isPopUpGalleryOpen,
		getImageFileNameByDevice: state => name => {
			const paths = {
				mobile: `${name}Mobile.png`,
				tablet: `${name}Tablet.png`,
				desktop: `${name}Desktop.png`,
			}

			return state.isMobile ? paths.mobile : state.isTablet ? paths.tablet : paths.desktop
		},
	},
})
window.addEventListener('scroll', () => {
	store.dispatch('handleScroll')
})

window.addEventListener('resize', () => {
	store.dispatch('handleResize')
})

function getVisibleSection(navbarLinks) {
	const headers = document.querySelectorAll('h1[id], h2[id]')
	let lastVisibleHeaderId = null

	for (const header of headers) {
		const rect = header.getBoundingClientRect()
		const windowHeight = window.innerHeight
		if (rect.top < windowHeight * 0.5) {
			lastVisibleHeaderId = header.id
		}
	}
	const matchingLink = navbarLinks.find(link => link.idHeader === lastVisibleHeaderId)
	if (matchingLink) {
		return matchingLink.name
	}
}
const handleOverflow = state => {
	if (state.isPopUpOfferOpen || state.isPopUpOpinionsOpen || state.isPopUpGalleryOpen) {
		document.body.style.overflow = 'hidden'
	} else {
		document.body.style.overflow = 'auto'
	}
}

export default store
