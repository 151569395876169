<template>
	<footer>
		<section>
			<div class="footer__logo">
				<img :src="getImagePath('logoBright')" alt="The Company's logo" />
				<p>Lorem ipsum dolor amet sit, consectetru adipiscing.</p>
			</div>
			<div class="footer__contact">
				<h3>KONTAKT</h3>
				<p class="contact__phone">
					<PhoneSvg class="icon"></PhoneSvg>
					<a href="tel:+48503580157">+48 503 580 157</a>
				</p>
				<p class="contact__email">
					<EmailSvg class="icon"></EmailSvg>
					<a href="mailto:urb.lozinski@gmail.com">urb.lozinski@gmail.com</a>
				</p>
				<p class="contact__opinions" @click="openReviewsPage"><LikeSvg class="icon"></LikeSvg>OPINIE</p>
			</div>

			<div class="footer__info">
				<h3>INFORMACJE</h3>
				<p>Usługi Remontowo-Budowlane Michał Łoziński</p>
				<p><span class="footer__info--span">NIP:</span> 6322024245</p>
				<p><span class="footer__info-span">REGON:</span> 384342993</p>
			</div>
		</section>
	</footer>
</template>

<script>
import EmailSvg from '@/assets/components/footer/EmailSvg.vue'
import LikeSvg from '@/assets/components/footer/LikeSvg.vue'
import PhoneSvg from '@/assets/components/footer/PhoneSvg.vue'
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Footer',
	components: { EmailSvg, LikeSvg, PhoneSvg },
	computed: {},
	methods: {
		getImagePath(name) {
			const fileName = this.$store.getters.getImageFileNameByDevice(name)
			return require(`@/assets/additions/${fileName}`)
		},
		openReviewsPage() {
			const reviewsPageUrl = 'https://g.page/r/CewGRfJxs3mdEBM/review'
			window.open(reviewsPageUrl, '_blank')
		},
	},
}
</script>
<style lang="scss" scoped>
footer {
	@include basic-section($background-color-tertiary);
	color: $text-color-secondary;
	padding-top: 35px;
	padding-bottom: 60px;
}
section {
	@include center-margin;
	@include flex(column, flex-start, center);
	width: 340px;
	position: relative;
	h3 {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 30px;
	}

	.footer__logo {
		font-size: 0;
	}

	.footer__contact {
		a {
			color: white;
			text-decoration: none;
		}

		p {
			margin-top: 20px;
			display: flex;

			cursor: pointer;
			transition: opacity 0.3s ease;
			.icon {
				margin-right: 20px;
			}
		}
		p:hover {
			opacity: 0.7;
		}

		.contact__opinions {
			letter-spacing: 1.1px;
			text-decoration: underline;
			transition: opacity 0.3s;
		}

	}

	.footer__info {
		p {
			margin-top: 20px;
			max-width: 250px;
			font-weight: 400;
		}
	}
}

@media (max-width: 1023px) {
	.footer__contact,
	.footer__info {
		margin-top: 60px;
	}
}
@media (min-width: 1024px) {
	footer {
		padding-top: 60px;
	}

	section {
		width: 920px;
		@include flex(row, none, space-between);

		h3 {
			margin-top: 20px;
			margin-bottom: 60px;
		}
	}
}

@media (min-width: 1500px) {
	footer {
		padding-top: 40px;
	}
}
</style>
