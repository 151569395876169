<template>
	<div>
		<svg width="40" height="40" viewBox="0 0 97 104" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_962_75)">
				<path
					d="M44.1601 92.6098C42.1801 86.8298 38.0301 82.2398 32.8201 79.6098C33.1201 79.3998 33.4001 79.1498 33.6401 78.8498C35.9301 75.8898 36.9301 72.2098 36.4501 68.4898C35.9801 64.7698 34.0801 61.4698 31.1201 59.1698C28.1501 56.8798 24.4701 55.8798 20.7601 56.3598C17.0401 56.8398 13.7301 58.7298 11.4401 61.6998C9.15006 64.6598 8.15006 68.3398 8.63006 72.0598C9.00006 74.9398 10.2201 77.5698 12.1301 79.6798C6.87006 82.3498 2.85007 86.9398 0.890065 92.6498C-0.509935 97.0698 1.95007 101.81 6.37007 103.21C7.19007 103.46 8.03007 103.59 8.89007 103.6H36.1701C38.9001 103.59 41.4701 102.27 43.0401 100.07C44.6101 97.9098 45.0301 95.1298 44.1601 92.6098ZM22.5101 63.4298C24.3601 63.4498 26.0901 64.1898 27.3801 65.5198C28.6701 66.8398 29.3701 68.5898 29.3501 70.4398C29.3301 72.5098 28.5701 74.6798 26.8501 75.9298C25.8201 76.6798 24.7501 76.8398 23.5301 77.0198C22.5201 77.1698 21.6201 77.1598 20.6201 76.8898C18.9301 76.4398 17.4801 75.3698 16.6301 73.8398C16.0301 72.7598 15.7401 71.5298 15.7401 70.2998C15.7401 66.9898 17.8601 63.5798 22.5101 63.4298ZM37.2501 95.8798L37.2301 95.8998C36.9901 96.2398 36.5901 96.4498 36.1701 96.4498H8.90006C8.47006 96.4498 8.08007 96.2398 7.81007 95.8798C7.61007 95.5998 7.56007 95.2498 7.67007 94.9298C9.90007 88.4198 16.0201 84.3098 22.5401 84.3098C24.2301 84.3098 25.9501 84.5898 27.6301 85.1698C32.2501 86.7498 35.8101 90.3098 37.3901 94.9198C37.5001 95.2498 37.4501 95.5998 37.2501 95.8798Z"
					fill="#232E51"
				/>
				<path
					d="M95.9601 92.6501V92.6101C94.0001 86.9501 89.9901 82.3601 84.7401 79.6901C88.0501 76.0901 89.1801 71.0201 87.6001 66.0601C85.7101 60.1001 80.5101 56.2401 74.3201 56.2801C66.6001 56.2901 60.3101 62.5801 60.3001 70.2101C60.2101 72.1001 61.2401 77.7601 64.0601 79.5901C58.8301 82.2201 54.6701 86.8301 52.6801 92.6501C51.2801 97.0701 53.7401 101.81 58.1601 103.21C58.9701 103.46 59.8201 103.59 60.6801 103.6H87.9601C92.6001 103.58 96.3601 99.8001 96.3501 95.1701C96.3501 94.3101 96.2201 93.4701 95.9601 92.6501ZM70.1301 64.8501C71.3401 63.9201 72.8001 63.4301 74.3001 63.4301C74.6001 63.4301 74.9001 63.4501 75.2101 63.4801C77.0201 63.7201 78.6401 64.6501 79.7601 66.1101C82.0701 69.1101 81.5101 73.4301 78.5101 75.7401C77.3101 76.6601 75.8201 77.1701 74.3101 77.1701C73.7701 77.1701 73.2501 77.2101 72.7201 77.1401C72.2501 77.0801 71.8101 77.0001 71.3701 76.8301C70.5201 76.4901 69.7601 75.8901 69.2401 75.1301C69.1001 74.9201 69.0001 74.7001 68.8801 74.4901C68.7601 74.2801 68.5901 74.0901 68.4601 73.8701C68.2101 73.4501 68.0001 73.0101 67.8401 72.5501C67.5301 71.6601 67.4101 70.7001 67.4901 69.7501C67.6401 67.8201 68.6201 66.0201 70.1501 64.8401L70.1301 64.8501ZM89.0301 95.8801V95.9001C88.7701 96.2401 88.3701 96.4501 87.9601 96.4501H60.6801C60.2601 96.4501 59.8601 96.2401 59.6001 95.8801C59.4001 95.6001 59.3501 95.2501 59.4501 94.9301C61.6901 88.4201 67.8001 84.3101 74.3301 84.3101C76.0201 84.3101 77.7401 84.5901 79.4201 85.1701C84.0401 86.7501 87.6001 90.3101 89.1801 94.9201C89.2901 95.2501 89.2401 95.6001 89.0301 95.8801Z"
					fill="#232E51"
				/>
				<path
					d="M55.03 41.27V36.05L65.88 44.73L65.9001 44.75C67.4601 45.96 69.7201 45.68 70.93 44.12C71.41 43.51 71.6701 42.74 71.68 41.94V35.92H74.5C78.23 35.92 81.27 32.88 81.27 29.15V6.77C81.27 3.04 78.23 0 74.5 0H42.5201C38.7901 0 35.75 3.04 35.75 6.77V16.15H21.82C18.37 16.15 15.56 18.97 15.55 22.42V41.26C15.55 44.71 18.37 47.52 21.82 47.53H23.63V52.06C23.66 54.02 25.26 55.6 27.22 55.6C27.24 55.6 27.25 55.6 27.27 55.6C28.05 55.59 28.81 55.32 29.44 54.83L38.5601 47.54H48.75C52.2001 47.54 55.0101 44.72 55.0201 41.27H55.03ZM47.8801 40.39H37.32C36.51 40.39 35.72 40.67 35.09 41.17L30.8 44.6V43.96C30.8 41.99 29.2 40.39 27.23 40.39H22.73V23.31H35.78V29.16C35.78 32.89 38.82 35.92 42.55 35.93H47.89V40.39H47.8801ZM42.92 28.78V7.15H74.14V28.78H68.1201C66.1501 28.78 64.5501 30.38 64.5501 32.35V34.5L58.37 29.55C57.74 29.04 56.9501 28.77 56.14 28.77H42.93L42.92 28.78Z"
					fill="#232E51"
				/>
			</g>
			<defs>
				<clipPath id="clip0_962_75">
					<rect width="95.85" height="103.6" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'ConsultingSvg',
	computed: {},
}
</script>
