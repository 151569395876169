<template>
	<svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.8462 7.425H34.3269C34.5054 7.425 34.6766 7.35388 34.8029 7.2273C34.9291 7.10071 35 6.92902 35 6.75V1.35C35 0.991958 34.8582 0.64858 34.6057 0.395406C34.3533 0.142232 34.0109 0 33.6538 0H19.5192C19.3407 0 19.1695 0.071116 19.0433 0.197703C18.9171 0.32429 18.8462 0.495979 18.8462 0.675V7.425ZM26.9231 17.55H34.3269C34.5054 17.55 34.6766 17.4789 34.8029 17.3523C34.9291 17.2257 35 17.054 35 16.875V10.8C35 10.621 34.9291 10.4493 34.8029 10.3227C34.6766 10.1961 34.5054 10.125 34.3269 10.125H26.9231V17.55ZM10.7692 10.125H24.2308V17.55H10.7692V10.125ZM8.07692 10.125H0.673077C0.494566 10.125 0.323366 10.1961 0.19714 10.3227C0.0709134 10.4493 0 10.621 0 10.8V16.875C0 17.054 0.0709134 17.2257 0.19714 17.3523C0.323366 17.4789 0.494566 17.55 0.673077 17.55H8.07692V10.125ZM18.8462 20.25V26.325C18.8462 26.504 18.9171 26.6757 19.0433 26.8023C19.1695 26.9289 19.3407 27 19.5192 27H33.6538C34.0109 27 34.3533 26.8578 34.6057 26.6046C34.8582 26.3514 35 26.008 35 25.65V20.925C35 20.746 34.9291 20.5743 34.8029 20.4477C34.6766 20.3211 34.5054 20.25 34.3269 20.25H18.8462ZM16.1538 7.425V0.675C16.1538 0.495979 16.0829 0.32429 15.9567 0.197703C15.8305 0.071116 15.6593 0 15.4808 0H1.34615C0.989132 0 0.646732 0.142232 0.394279 0.395406C0.141826 0.64858 0 0.991958 0 1.35V6.75C0 6.92902 0.0709134 7.10071 0.19714 7.2273C0.323366 7.35388 0.494566 7.425 0.673077 7.425H16.1538ZM16.1538 20.25H0.673077C0.494566 20.25 0.323366 20.3211 0.19714 20.4477C0.0709134 20.5743 0 20.746 0 20.925V25.65C0 26.008 0.141826 26.3514 0.394279 26.6046C0.646732 26.8578 0.989132 27 1.34615 27H15.4808C15.6593 27 15.8305 26.9289 15.9567 26.8023C16.0829 26.6757 16.1538 26.504 16.1538 26.325V20.25Z"
			:fill="'url(#' + gradient.id + ')'"
		/>
		<defs>
			<linearGradient :id="gradient.id" x1="-8.421e-07" y1="-1.5" x2="17.5" y2="24" gradientUnits="userSpaceOnUse">
				<stop v-for="(stop, index) in gradient.stops" :key="index" :offset="stop.offset" :stop-color="stop.color" />
			</linearGradient>
		</defs>
	</svg>
</template>

<script>
export default {
	props: ['gradient'],
	name: 'ChipSvg',
	computed: {},
}
</script>
