<template>
	<svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.2315 0.55479L17.2304 2.37066L3.24235 15.0778C2.97695 15.319 2.82786 15.646 2.82786 15.9871C2.82786 16.3281 2.97695 16.6551 3.24235 16.8963L17.2063 29.579L15.2046 31.3975L1.24345 18.7147C0.447273 17.9912 1.38456e-06 17.0101 1.48301e-06 15.9871C1.58146e-06 14.964 0.447273 13.9829 1.24345 13.2594L15.2315 0.55479Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'ArrowLeftPopUpSvg',
	computed: {},
}
</script>
