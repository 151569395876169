<template>
	<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.39373 3.66996L1.20749 4.13246L1.15106 4.14396C1.06563 4.16667 0.987747 4.21167 0.925369 4.27436C0.862991 4.33706 0.818351 4.41521 0.796006 4.50082C0.773662 4.58644 0.774414 4.67647 0.798186 4.7617C0.821959 4.84693 0.867899 4.92432 0.931316 4.98596L3.23959 7.23546L2.69524 10.413L2.68874 10.468C2.68351 10.5564 2.70186 10.6447 2.74191 10.7237C2.78195 10.8027 2.84225 10.8697 2.91664 10.9177C2.99103 10.9657 3.07683 10.9931 3.16526 10.997C3.25368 11.0009 3.34156 10.9812 3.41988 10.94L6.26952 9.43996L9.11267 10.94L9.16261 10.963C9.24504 10.9955 9.33463 11.0054 9.42218 10.9918C9.50973 10.9782 9.59209 10.9416 9.66082 10.8856C9.72954 10.8296 9.78216 10.7563 9.81326 10.6733C9.84436 10.5902 9.85284 10.5004 9.83781 10.413L9.29295 7.23546L11.6022 4.98546L11.6412 4.94296C11.6968 4.87435 11.7333 4.79219 11.7469 4.70486C11.7605 4.61753 11.7508 4.52815 11.7186 4.44583C11.6865 4.3635 11.6332 4.29118 11.564 4.23622C11.4949 4.18126 11.4124 4.14563 11.3251 4.13296L8.13882 3.66996L6.7145 0.779961C6.67328 0.696229 6.60948 0.625719 6.53031 0.576414C6.45114 0.527108 6.35976 0.500977 6.26652 0.500977C6.17329 0.500977 6.08191 0.527108 6.00274 0.576414C5.92357 0.625719 5.85977 0.696229 5.81855 0.779961L4.39373 3.66996Z"
			fill="#232E51"
		/>
	</svg>
</template>

<script>
export default {
	name: 'StarDarkSvg',
	computed: {},
}
</script>
