<template>
	<svg
		:width="isMobile ? '22' : '32'"
		:height="isMobile ? '22' : '32'"
		viewBox="0 0 23 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_45_333)">
			<path
				d="M4.26984 17.8127H7.83238L17.184 8.46101C17.418 8.22709 17.6035 7.94939 17.7301 7.64376C17.8567 7.33813 17.9219 7.01055 17.9219 6.67974C17.9219 6.34893 17.8567 6.02136 17.7301 5.71573C17.6035 5.41009 17.418 5.13239 17.184 4.89847C16.9501 4.66455 16.6724 4.479 16.3668 4.3524C16.0612 4.2258 15.7336 4.16064 15.4028 4.16064C15.072 4.16064 14.7444 4.2258 14.4388 4.3524C14.1331 4.479 13.8554 4.66455 13.6215 4.89847L4.26984 14.2501V17.8127Z"
				stroke="#BABABA"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.7308 5.78906L16.2934 9.3516"
				stroke="#BABABA"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_45_333">
				<rect width="21.3752" height="21.3752" fill="white" transform="translate(0.707275)" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'PencilSvg',
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
}
</script>
