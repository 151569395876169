<template>
	<ArrowLeft class="carousel-control left" :class="{ disabled }" @click="$emit('prev')"></ArrowLeft>
	<ArrowRight class="carousel-control right" :class="{ disabled }" @click="$emit('next')"></ArrowRight>
</template>

<script>
import ArrowRight from '@/assets/components/sectionGallery/ArrowRightPopUpSvg.vue'
import ArrowLeft from '@/assets/components/sectionGallery/ArrowLeftPopUpSvg.vue'

export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: { ArrowLeft, ArrowRight },
	emits: ['prev', 'next'],
}
</script>

<style lang="scss" scoped>
.carousel-control {
	position: absolute;
	top: calc(50% - 25px);
	cursor: pointer;
}
.left {
	left: 15px;
}
.right {
	right: 15px;
}

.disabled {
	cursor: auto;
	opacity: 0.5;
}

@media (min-width: 1024px) {
	.left {
		left: 15px;
	}
	.right {
		right: 15px;
	}
}

@media (min-width: 1500px) {
	.carousel-control {
		height: 50px;
		width: 30px;
	}
}
</style>
