<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_45_101)">
			<path
				d="M17.657 12.007C17.4235 12.031 17.1999 12.1138 17.007 12.2475C16.8142 12.3813 16.6583 12.5617 16.554 12.772L15.699 14.495L13.792 14.772C13.272 14.844 12.832 15.212 12.668 15.716L12.63 15.856C12.53 16.321 12.676 16.81 13.023 17.146L14.4 18.483L14.074 20.375C14.0313 20.6305 14.0606 20.8928 14.1587 21.1326C14.2569 21.3723 14.4199 21.5799 14.6295 21.7321C14.8392 21.8843 15.0871 21.975 15.3454 21.9941C15.6038 22.0131 15.8623 21.9598 16.092 21.84L17.8 20.945L19.508 21.841C19.7372 21.9617 19.9956 22.0158 20.254 21.9972C20.5124 21.9787 20.7604 21.8882 20.97 21.736L21.082 21.646C21.2574 21.4888 21.39 21.2896 21.4673 21.0671C21.5446 20.8446 21.5641 20.6061 21.524 20.374L21.199 18.483L22.579 17.144C22.959 16.773 23.095 16.22 22.931 15.717L22.88 15.583C22.7843 15.3683 22.6356 15.1813 22.4479 15.0396C22.2603 14.898 22.0398 14.8062 21.807 14.773L19.9 14.495L19.047 12.773C18.9316 12.5409 18.7538 12.3456 18.5335 12.209C18.3132 12.0725 18.0592 12.0001 17.8 12L17.657 12.007Z"
				fill="#222F51"
			/>
			<path
				d="M6.05699 12.007C5.8235 12.031 5.5999 12.1138 5.40703 12.2475C5.21416 12.3813 5.0583 12.5617 4.95399 12.772L4.09899 14.495L2.19199 14.772C1.67199 14.844 1.23199 15.212 1.06799 15.716L1.02999 15.856C0.929992 16.321 1.07599 16.81 1.42299 17.146L2.79999 18.483L2.47399 20.375C2.43125 20.6305 2.46059 20.8928 2.55873 21.1326C2.65687 21.3723 2.81991 21.5799 3.02954 21.7321C3.23917 21.8843 3.48708 21.975 3.74542 21.9941C4.00376 22.0131 4.2623 21.9598 4.49199 21.84L6.19999 20.945L7.90799 21.841C8.13721 21.9617 8.39561 22.0158 8.65398 21.9972C8.91235 21.9787 9.16037 21.8882 9.36999 21.736L9.48199 21.646C9.65741 21.4888 9.79 21.2896 9.86731 21.0671C9.94462 20.8446 9.96413 20.6061 9.92399 20.374L9.59999 18.483L10.98 17.144C11.36 16.773 11.496 16.22 11.332 15.717L11.281 15.583C11.1852 15.3683 11.0366 15.1813 10.8489 15.0396C10.6613 14.898 10.4408 14.8062 10.208 14.773L8.29999 14.494L7.44699 12.772C7.33149 12.5401 7.1536 12.345 6.93332 12.2086C6.71303 12.0722 6.45908 12 6.19999 12L6.05699 12.007Z"
				fill="#222F51"
			/>
			<path
				d="M11.857 2.007C11.6235 2.03101 11.3999 2.11376 11.207 2.24753C11.0142 2.3813 10.8583 2.56173 10.754 2.772L9.89901 4.495L7.99201 4.772C7.47201 4.844 7.03201 5.212 6.86801 5.716L6.83001 5.856C6.73001 6.321 6.87601 6.81 7.22301 7.146L8.60001 8.483L8.27401 10.375C8.23127 10.6305 8.26061 10.8928 8.35875 11.1326C8.45689 11.3723 8.61993 11.5799 8.82956 11.7321C9.03918 11.8843 9.2871 11.975 9.54544 11.9941C9.80378 12.0131 10.0623 11.9598 10.292 11.84L12 10.946L13.709 11.842C13.9382 11.9627 14.1966 12.0168 14.455 11.9982C14.7134 11.9797 14.9614 11.8892 15.171 11.737L15.283 11.647C15.4584 11.4898 15.591 11.2906 15.6683 11.0681C15.7456 10.8456 15.7651 10.6071 15.725 10.375L15.4 8.483L16.78 7.144C17.16 6.773 17.296 6.22 17.132 5.717L17.081 5.583C16.9853 5.36826 16.8366 5.18131 16.649 5.03965C16.4613 4.89799 16.2408 4.80624 16.008 4.773L14.1 4.494L13.247 2.772C13.1315 2.54009 12.9536 2.34498 12.7333 2.2086C12.513 2.07222 12.2591 1.99999 12 2L11.857 2.007Z"
				fill="#222F51"
			/>
		</g>
		<defs>
			<clipPath id="clip0_45_101">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'StarsSvg',
	computed: {},
}
</script>
