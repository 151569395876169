<template>
	<svg
		:width="isMobile ? '22' : '32'"
		:height="isMobile ? '22' : '32'"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_45_322)">
			<path
				d="M7.16437 10.6875C7.16437 11.6324 7.53971 12.5385 8.20781 13.2066C8.87592 13.8747 9.78206 14.2501 10.7269 14.2501C11.6718 14.2501 12.5779 13.8747 13.246 13.2066C13.9141 12.5385 14.2894 11.6324 14.2894 10.6875C14.2894 9.7427 13.9141 8.83655 13.246 8.16844C12.5779 7.50034 11.6718 7.125 10.7269 7.125C9.78206 7.125 8.87592 7.50034 8.20781 8.16844C7.53971 8.83655 7.16437 9.7427 7.16437 10.6875Z"
				stroke="#BABABA"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14.2895 10.6876V12.0236C14.2895 12.6141 14.5241 13.1805 14.9417 13.598C15.3592 14.0156 15.9256 14.2502 16.5161 14.2502C17.1066 14.2502 17.673 14.0156 18.0905 13.598C18.5081 13.1805 18.7427 12.6141 18.7427 12.0236V10.6876C18.7449 8.96524 18.1922 7.28794 17.1666 5.90421C16.141 4.52047 14.6969 3.50391 13.0483 3.00511C11.3997 2.50631 9.63429 2.5518 8.01358 3.13484C6.39287 3.71789 5.00306 4.80747 4.05005 6.24219C3.09704 7.67691 2.63151 9.38045 2.72242 11.1004C2.81334 12.8204 3.45586 14.4654 4.55482 15.7917C5.65377 17.1179 7.15069 18.0549 8.82384 18.4639C10.497 18.8728 12.2574 18.7319 13.8442 18.0621"
				stroke="#BABABA"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_45_322">
				<rect width="21.3752" height="21.3752" fill="white" transform="translate(0.0393066)" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'AtSvg',
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
}
</script>
