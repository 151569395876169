import { createApp } from 'vue'
import store from '../src/shared/store.js'
import App from '../src/App.vue'

const initialWidth = window.innerWidth
store.commit('setDevice', initialWidth)

const app = createApp(App)
app.use(store)

app.mount('#app')
