<template>
	<svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.66669 8.49984C5.66669 9.25129 5.9652 9.97195 6.49655 10.5033C7.0279 11.0347 7.74857 11.3332 8.50002 11.3332C9.25147 11.3332 9.97214 11.0347 10.5035 10.5033C11.0348 9.97195 11.3334 9.25129 11.3334 8.49984C11.3334 7.74839 11.0348 7.02772 10.5035 6.49637C9.97214 5.96501 9.25147 5.6665 8.50002 5.6665C7.74857 5.6665 7.0279 5.96501 6.49655 6.49637C5.9652 7.02772 5.66669 7.74839 5.66669 8.49984Z"
			stroke="url(#paint0_linear_48_15)"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.3333 8.49989V9.56239C11.3333 10.032 11.5199 10.4825 11.852 10.8146C12.1841 11.1467 12.6345 11.3332 13.1042 11.3332C13.5738 11.3332 14.0242 11.1467 14.3563 10.8146C14.6884 10.4825 14.875 10.032 14.875 9.56239V8.49989C14.8768 7.13005 14.4372 5.79607 13.6215 4.69557C12.8058 3.59507 11.6573 2.78658 10.3462 2.38988C9.03505 1.99317 7.63099 2.02935 6.34202 2.49306C5.05304 2.95676 3.94771 3.82332 3.18977 4.96437C2.43183 6.10542 2.06158 7.46026 2.13389 8.8282C2.2062 10.1961 2.71721 11.5044 3.59122 12.5592C4.46523 13.614 5.65575 14.3592 6.98643 14.6844C8.3171 15.0096 9.71715 14.8976 10.9792 14.3649"
			stroke="url(#paint1_linear_48_15)"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_48_15"
				x1="8.50002"
				y1="5.6665"
				x2="8.50002"
				y2="11.3332"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C6A04D" />
				<stop offset="0.28125" stop-color="#EFE19E" />
				<stop offset="0.510417" stop-color="#C6A04D" />
				<stop offset="0.75" stop-color="#EFE19E" />
				<stop offset="1" stop-color="#C6A04D" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_48_15"
				x1="8.5"
				y1="2.1167"
				x2="8.5"
				y2="14.8667"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C6A04D" />
				<stop offset="0.28125" stop-color="#EFE19E" />
				<stop offset="0.510417" stop-color="#C6A04D" />
				<stop offset="0.75" stop-color="#EFE19E" />
				<stop offset="1" stop-color="#C6A04D" />
			</linearGradient>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'EmailSvg',
	computed: {},
}
</script>
