<template>
	<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_377_234)">
			<path
				d="M4.12151 3.67002L0.931505 4.13252L0.875005 4.14402C0.789475 4.16673 0.711502 4.21173 0.649051 4.27442C0.5866 4.33712 0.541907 4.41527 0.519536 4.50089C0.497165 4.5865 0.497919 4.67653 0.521719 4.76176C0.545519 4.84699 0.591513 4.92438 0.655005 4.98602L2.96601 7.23552L2.42101 10.413L2.41451 10.468C2.40927 10.5565 2.42764 10.6447 2.46773 10.7238C2.50782 10.8028 2.5682 10.8698 2.64267 10.9178C2.71715 10.9658 2.80305 10.9932 2.89158 10.9971C2.98011 11.001 3.06809 10.9813 3.14651 10.94L5.99951 9.44002L8.846 10.94L8.896 10.963C8.97854 10.9955 9.06823 11.0055 9.15588 10.9919C9.24354 10.9783 9.326 10.9416 9.3948 10.8857C9.46361 10.8297 9.51628 10.7564 9.54742 10.6733C9.57856 10.5903 9.58705 10.5004 9.572 10.413L9.0265 7.23552L11.3385 4.98552L11.3775 4.94302C11.4332 4.87441 11.4697 4.79225 11.4834 4.70492C11.497 4.61759 11.4872 4.52821 11.455 4.44589C11.4229 4.36356 11.3695 4.29124 11.3002 4.23628C11.231 4.18132 11.1485 4.14569 11.061 4.13302L7.87101 3.67002L6.44501 0.780022C6.40374 0.69629 6.33986 0.62578 6.2606 0.576475C6.18134 0.527169 6.08985 0.501038 5.99651 0.501038C5.90316 0.501038 5.81167 0.527169 5.73241 0.576475C5.65315 0.62578 5.58927 0.69629 5.54801 0.780022L4.12151 3.67002Z"
				fill="url(#paint0_linear_377_234)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_377_234"
				x1="3"
				y1="0.500061"
				x2="11"
				y2="11.0001"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C6A04D" />
				<stop offset="0.244792" stop-color="#EFE19E" />
				<stop offset="0.484375" stop-color="#C6A04D" />
				<stop offset="0.71875" stop-color="#EFE19E" />
				<stop offset="1" stop-color="#C6A04D" />
			</linearGradient>
			<clipPath id="clip0_377_234">
				<rect width="12" height="12" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'StarBrightSvg',
	computed: {},
}
</script>
