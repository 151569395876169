export const galleriesArr = [
	{
		name: 'Jaworzno',
		text: 'Kompleksowy remont łazienki obejmujący prace instalacyjne.',
		date: '2023-08',
		imagesFolder: 'jaworzno08_22',
	},
	{
		name: 'Orzesze',
		text: 'Kompleksowe wykończenie nowego domu w Orzeszu.',
		date: '2022-06',
		imagesFolder: 'orzesze06_22',
	},
	{
		name: 'Jaworzno',
		text: 'Kompleksowy remont mieszkania w Jaworznie obejmujący całościową wymianę wszystkich instalacji i wykonanie kuchni i mebli na wymiar.',
		date: '2023-01',
		imagesFolder: 'jaworzno01_23',
	},
	{
		name: 'Katowice',
		text: 'Kompleksowe wykończenie mieszkania wykonane pod klucz - łącznie z montażem kuchni.',
		date: '2021-09',
		imagesFolder: 'katowice09_21',
	},
	{
		name: 'Jaworzno',
		text: 'Wykończenie łazienki w nowym domu w Jaworznie.',
		date: '2022-08',
		imagesFolder: 'jaworzno08_22second',
	},
]
