<template>
	<svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.54167 2.8335H6.375L7.79167 6.37516L6.02083 7.43766C6.77943 8.97583 8.02433 10.2207 9.5625 10.9793L10.625 9.20849L14.1667 10.6252V13.4585C14.1667 13.8342 14.0174 14.1946 13.7517 14.4602C13.4861 14.7259 13.1257 14.8752 12.75 14.8752C9.98698 14.7073 7.38094 13.5339 5.42359 11.5766C3.46623 9.61922 2.29291 7.01318 2.125 4.25016C2.125 3.87444 2.27426 3.5141 2.53993 3.24843C2.80561 2.98275 3.16594 2.8335 3.54167 2.8335Z"
			stroke="url(#paint0_linear_48_12)"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_48_12"
				x1="8.14583"
				y1="2.8335"
				x2="8.14583"
				y2="14.8752"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#C6A04D" />
				<stop offset="0.28125" stop-color="#EFE19E" />
				<stop offset="0.510417" stop-color="#C6A04D" />
				<stop offset="0.75" stop-color="#EFE19E" />
				<stop offset="1" stop-color="#C6A04D" />
			</linearGradient>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'PhoneSvg',
	computed: {},
}
</script>
