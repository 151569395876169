<template>
	<svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.9989 31.3974L0 29.5815L13.988 16.8743C14.2534 16.6331 14.4025 16.3061 14.4025 15.9651C14.4025 15.6241 14.2534 15.297 13.988 15.0559L0.0240663 2.37312L2.02579 0.554687L15.9869 13.2374C16.7831 13.9609 17.2304 14.9421 17.2304 15.9651C17.2304 16.9881 16.7831 17.9692 15.9869 18.6927L1.9989 31.3974Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: 'ArrowRightPopUpSvg',
	computed: {},
}
</script>
