<template>
	<svg width="62" height="68" viewBox="0 0 62 68" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M33.3846 -1.45929e-06L33.3846 58.8277L58.4231 33.6158L62 36.7853L31 68L-1.36444e-06 36.7853L3.57692 33.6158L28.6154 58.8277L28.6154 -1.25082e-06L33.3846 -1.45929e-06Z"
			fill="url(#paint0_linear_58_300)"
		/>
		<defs>
			<linearGradient id="paint0_linear_58_300" x1="-15.5" y1="2.5" x2="59.5" y2="60" gradientUnits="userSpaceOnUse">
				<stop stop-color="#C6A04D" />
				<stop offset="0.239583" stop-color="#EFE19E" />
				<stop offset="0.515625" stop-color="#C6A04D" />
				<stop offset="0.765625" stop-color="#EFE19E" />
				<stop offset="1" stop-color="#C6A04D" />
			</linearGradient>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'ArrowSvg',
	computed: {},
}
</script>
