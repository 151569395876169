<template>
	<div>
		<div class="carousel" @touchstart="startTouch" @touchmove="moveTouch" @touchend="endTouch">
			<div class="carousel-inner">
				<carousel-item
					v-for="(slidePath, index) in slides"
					:key="`item-${index}`"
					:slidePath="getGalleryImage(openedName, slidePath)"
					:index="index"
					:direction="direction"
					:currentSlide="currentSlide"
				></carousel-item>
			</div>
			<carousel-controls @prev="prev" @next="next" :disabled="isAnimating"></carousel-controls>
		</div>
		<CrossSvg :strokeColor="'#ffffff'" class="crossSvg" @click="togglePopUp" />
	</div>
</template>

<script>
import CarouselItem from './CarouselItem.vue'
import CarouselControls from './CarouselControls.vue'
import CrossSvg from '@/assets/additions/CrossSvg.vue'

export default {
	props: {
		slides: {
			type: Array,
			required: true,
		},
		openedName: {
			type: Object,
			required: true,
		},
		imagesContext: {
			type: Object,
			required: true,
		},
		currentPhoto: {
			type: Number,
			required: true,
		},
	},
	components: { CarouselItem, CarouselControls, CrossSvg },
	data() {
		return {
			currentSlide: 0,
			currentGalleryLength: 0,
			direction: 'right',
			isAnimating: false,
		}
	},
	created() {
		this.updateCurrentGalleryLength()
		this.currentSlide = this.currentPhoto
	},
	methods: {
		togglePopUp() {
			this.$store.commit('togglePopUpGallery')
		},
		updateCurrentGalleryLength() {
			const galleryName = this.openedName.imagesFolder
			const context = this.imagesContext[galleryName]
			this.currentGalleryLength = context ? context.keys().length : 0
		},
		setCurrentSlide(index) {
			this.currentSlide = index
		},
		prev() {
			if (!this.isAnimating) {
				this.isAnimating = true
				const step = -1
				const index = this.currentSlide === 0 ? this.currentGalleryLength - 1 : this.currentSlide + step
				this.setCurrentSlide(index)
				this.direction = 'left'
				setTimeout(() => {
					this.isAnimating = false
				}, 300)
			}
		},
		next() {
			if (!this.isAnimating) {
				this.isAnimating = true
				const step = 1
				const index = this.currentSlide === this.currentGalleryLength - 1 ? 0 : this.currentSlide + step
				this.setCurrentSlide(index)
				this.direction = 'right'
				setTimeout(() => {
					this.isAnimating = false
				}, 300)
			}
		},
		getGalleryImage(gallery, slidePath) {
			return `/img/galleries/${gallery.imagesFolder}/${slidePath}`
		},
		startTouch(evt) {
			this.xDown = evt.touches[0].clientX
			this.yDown = evt.touches[0].clientY
		},
		moveTouch(evt) {
			if (!this.xDown || !this.yDown) {
				return
			}

			let xUp = evt.touches[0].clientX
			let yUp = evt.touches[0].clientY

			let xDiff = this.xDown - xUp
			let yDiff = this.yDown - yUp

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				if (xDiff > 0) {
					this.next()
				} else {
					this.prev()
				}
			}
			this.xDown = null
			this.yDown = null
		},
		endTouch() {
			this.xDown = null
			this.yDown = null
		},
	},
}
</script>

<style lang="scss" scoped>
.carousel {
	display: flex;
	align-items: center;
}
.carousel-inner {
	position: relative;
	width: 325px;
	height: 325px;
	overflow: hidden;
}

.crossSvg {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

@media (min-width: 1024px) {
	.carousel-inner {
		width: 555px;
		height: 525px;
	}
}
@media (min-width: 1500px) {
	.carousel-inner {
		width: 755px;
		height: 725px;
	}

	.crossSvg {
		height: 30px;
		width: 30px;
		top: 40px;
		right: 40px;
	}
}
</style>
