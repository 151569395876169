<template>
	<div
		:class="['containerOpinion', isSection ? 'containerSection' : 'containerPopUp']"
		:section-boolean="isSection"
		:popup-boolean="isPopUp"
	>
		<div class="container__header">
			<HumanBrightSvg v-if="isSection" class="header__svg header__svg--bright"></HumanBrightSvg>
			<HumanDarkSvg v-if="isPopUp" class="header__svg header__svg--dark"></HumanDarkSvg>
			<p class="header__name">{{ opinionData.name }}</p>
			<p class="header__stars" v-if="isSection">
				<StarBrightSvg v-for="star in parseInt(opinionData.stars)" :key="star" />
			</p>
			<p class="header__stars" v-if="isPopUp">
				<StarDarkSvg v-for="star in parseInt(opinionData.stars)" :key="star" />
			</p>
		</div>
		<p class="container__content">{{ opinionData.opinion }}</p>
		<button v-if="isSection" @click="togglePopUp" class="sectionBtn">Zobacz więcej</button>
	</div>
</template>

<script>
import HumanBrightSvg from '@/assets/components/sectionAbout/HumanBrightSvg.vue'
import HumanDarkSvg from '@/assets/components/sectionAbout/HumanDarkSvg.vue'
import StarBrightSvg from '@/assets/components/sectionAbout/StarBrightSvg.vue'
import StarDarkSvg from '@/assets/components/sectionAbout/StarDarkSvg.vue'

export default {
	name: 'OpinionItem',
	components: { HumanBrightSvg, HumanDarkSvg, StarBrightSvg, StarDarkSvg },
	props: {
		opinionData: Object,
		nameParent: String,
	},
	computed: {
		isSection() {
			return this.nameParent === 'Section'
		},
		isPopUp() {
			return this.nameParent === 'PopUp'
		},
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
	methods: {
		togglePopUp() {
			this.$store.commit('togglePopUpOpinions')
		},
	},
}
</script>
<style lang="scss" scoped>
.containerOpinion {
	@include center-margin;
	padding: 20px 40px;

	.container__header {
		display: flex;
		align-items: center;
		margin-left: -30px;

		.header__svg {
			margin-right: 10px;
		}

		.header__name {
			position: relative;
			margin-right: 60px;
			max-width: 110px;
		}
		.header__name::after {
			@include position-pseudoClass(0px, -30px, none, none, 100%, 1px, none);
		}
	}

	.container__content {
		margin-top: 15px;
		font-size: 14px;
		font-weight: 400;
	}
}
.containerSection {
	position: relative;
	color: $text-color-secondary;
	pointer-events: none;
	border-radius: 0 32px 32px 32px;
	border: 1px solid #d2b064;

	.container__content {
		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.header__name::after {
		background-color: $background-color-primary;
	}
}

.sectionBtn {
	@include button-noStyle;
	margin-top: 5px;
	font-weight: 600;
	font-size: 14px;
	pointer-events: auto;
	z-index: 2;
	cursor: pointer;
}

.containerPopUp {
	width: 380px;
	color: #232f51;
	border: 1px solid #232f51;
	border-radius: 0 32px 32px 32px;
	.container__header {
		.header__name {
			max-width: 170px;
		}
		.header__name::after {
			background-color: #232f51;
		}
	}
}
@media (max-width: 1023px) {
}
@media (min-width: 1024px) {
	.containerSection {
		.container__header {
			.header__name {
				max-width: 170px;
			}
		}
	}
}
@media (min-width: 1500px) {
	.containerSection {
		.container__header {
			.header__name {
				max-width: 320px;
			}
		}
	}

	.containerPopUp {
		width: 510px;
		.container__header {
			.header__name {
				max-width: 300px;
			}
		}
	}
}
</style>
