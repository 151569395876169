export const opinionsArr = [
	{
		name: 'Michel',
		stars: 5,
		opinion:
			'Zakres prac wykonanych obejmował uzupełnienie ubytków i malowanie ścian w domu o powierzchni 120 m2 + 1 cała łazienka. Jakość wykonanych prac bez zarzutu, a trzeba wspomnieć że jestem z natury perfekcjonistą 🙂 Nowe pomysły i modyfikacje dotychczasowych - też bezproblemowo po szybkich uzgodnieniach. Cenowo poprawnie, nieabsurdalnie - co dziś nie jest takie oczywiste. Jak najbardziej polecam.',
	},
	{
		name: 'Jan Kowalski',
		stars: 5,
		opinion:
			'Solidna firma. Godna polecenia. Właściciel z dużą wiedzą i odpowie na każde nurtujące cię pytania. Najwyższa precyzja w najszybszym możliwym tempie. Dla Pana Michała nie ma rzeczy niemożliwych.',
	},
	{
		name: 'Jacek Radziwonuiuka',
		stars: 5,
		opinion: null,
	},
	{
		name: 'Konrad Pierzynka',
		stars: 5,
		opinion: null,
	},
	{
		name: 'Natasha Bandżej',
		stars: 5,
		opinion: null,
	},
]
