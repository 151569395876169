<template>
	<transition :name="transitionEffect">
		<div class="carousel-item" v-show="currentSlide === index">
			<img :src="slidePath" />
		</div>
	</transition>
</template>

<script>
export default {
	props: ['slidePath', 'currentSlide', 'index', 'direction'],
	mounted() {},
	computed: {
		transitionEffect() {
			return this.direction === 'right' ? 'slide-out' : 'slide-in'
		},
	},
}
</script>

<style lang="scss">
.carousel-item {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	img {
		width: 99%;
		height: 100%;
		object-fit: contain;
	}
}

@include carouselAnimationGallery;
</style>
