<template>
	<svg
		:width="isMobile ? '22' : '32'"
		:height="isMobile ? '22' : '32'"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.16443 6.23442C7.16443 7.17926 7.53977 8.08541 8.20787 8.75351C8.87598 9.42162 9.78213 9.79696 10.727 9.79696C11.6718 9.79696 12.578 9.42162 13.2461 8.75351C13.9142 8.08541 14.2895 7.17926 14.2895 6.23442C14.2895 5.28957 13.9142 4.38342 13.2461 3.71532C12.578 3.04721 11.6718 2.67188 10.727 2.67188C9.78213 2.67188 8.87598 3.04721 8.20787 3.71532C7.53977 4.38342 7.16443 5.28957 7.16443 6.23442Z"
			stroke="#BABABA"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.38312 18.7037V16.9224C5.38312 15.9776 5.75846 15.0714 6.42656 14.4033C7.09467 13.7352 8.00081 13.3599 8.94566 13.3599H12.5082C13.453 13.3599 14.3592 13.7352 15.0273 14.4033C15.6954 15.0714 16.0707 15.9776 16.0707 16.9224V18.7037"
			stroke="#BABABA"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'HumanSvg',
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
	},
}
</script>
