export const offersArr = [
	{

		name: 'Prace remontowe i wykończeniowe',
		text: 'Dzięki długoletniemu doświadczeniu w pracach remontowo-wykończeniowych, precyzji wykonania i dbałości o każdy szczegół kompleksowo realizujemy najbardziej wymagające projekty.',
		icon: 'RenovationWorksSvg',
		image: 'praceRemontowe.png',
	},
	{
		name: 'Prace instalacyjne',
		text: 'Wykonujemy zarówno instalacje elektryczne i ich pochodne, jak i instalacje centralnego ogrzewania, gazowe, wod.-kan. czy rekuperację. Wszystko na najwyższej jakości komponentach i osprzęcie oraz poparte wszelkimi wymaganymi odbiorami i badaniami.',
		icon: 'InstallationWorksSvg',
		image: 'praceInstalacyjne.png',
	},
	{
		name: 'Prace budowlane',
		text: 'Prowadzimy kompleksowe realizacje rozpoczynając od projektu, poprzez budowę, a kończąc na wykończeniu, zarówno w technologii szkieletowej, jak i budownictwie tradycyjnym.',
		icon: 'BuildingWorksSvg',
		image: 'praceBudowlane.png',
	},
	{
		name: 'Doradztwo',
		text: 'Jeśli nasza firma nie ma wolnych terminów, zawsze możemy Państwa wspomóc merytorycznie na budowie. Wspólnie z inną firmą budowlaną podejmować kluczowe decyzje, dobrać technologię wykonania czy pomóc rozplanować inwestycje.',
		icon: 'ConsultingSvg',
		image: 'doradztwo.png',
	},
	{
		name: 'Metody natryskowe',
		text: 'Kolejną specjalizacją naszej firmy jest malowanie i wykonywanie gładzi metodą natryskową. Pomagają w tym jedne z najlepszych dostępnych na rynku agregatów marki GRACO. Jakość wykonania wykończonych w ten sposób powierzchni i tempo pracy jest nie do uzyskania tradycyjnymi metodami.',
		icon: 'SprayMethodsSvg',
		image: 'metodyNatryskowe.png',
	},
]
