<template>
	<section>
		<div v-if="isPopUpGalleryOpen" class="overlay" @click="togglePopUp"></div>
		<div class="sectionGallery">
			<div class="sectionGallery__header">
				<header>
					<h2 id="HeaderSectionGallery">Galeria realizacji</h2>
					<p>
						Zapraszamy do obejrzenia naszej galerii realizacji – prawdziwe dowody na to, że każdy nasz projekt to nie
						tylko prace budowlane, ale także historie udanych współprac i zadowolonych klientów.
					</p>
				</header>
			</div>
			<article class="sectionGallery__gallery" @touchstart="startTouch" @touchmove="moveTouch" @touchend="endTouch">
				<div class="gallery__wrapper">
					<div v-for="(gallery, index) in sortedGalleriesArr" :key="`item-${index}`">
						<transition :name="transitionEffect">
							<div v-show="currentGallery === index" class="gallery__content">
								<img
									:src="getGalleryImage(gallery, 0, null)"
									@click="togglePopUp(0)"
									class="gallery__mainImg"
									alt="Photo of gallery"
									data-cursor="pointer"
								/>
								<div class="gallery__header">
									<h3>{{ gallery.name }}</h3>
									<p>{{ gallery.text }}</p>
								</div>
								<div class="gallery__addition">
									<div class="gallery__icons">
										<BrushSvg :gradient="incrementId(gradient, index)" />
										<ChipSvg :gradient="incrementId(gradient, index)" />
										<HammerSvg :gradient="incrementId(gradient, index)" />
									</div>
									<p>{{ gallery.date }}</p>
								</div>
								<div class="li__additionsImgs">
									<img
										v-for="(image, index) in gallery.imagesFolder.slice(1, isTablet ? 3 : 4)"
										:key="index"
										:src="getGalleryImage(gallery, index + 1, 'min')"
										@click="togglePopUp(index + 1)"
										alt="Photo of gallery"
										data-cursor="pointer"
									/>
								</div>
							</div>
						</transition>
					</div>
				</div>
				<div v-if="sortedGalleriesArr.length > 0" class="gallery__nav" data-cursor="pointer">
					<ArrowLeftSvg
						v-if="currentGallery > 0"
						@click="goToGallery(currentGallery - 1)"
						class="arrow-button"
						data-cursor="pointer"
					/>
					<div class="navBar__pages">
						<button
							v-if="currentGallery >= 0"
							@click="goToGallery(0)"
							:class="{ active: currentGallery === 0 }"
							data-cursor="pointer"
						>
							{{ 1 }}
						</button>
						<span v-if="shouldShowDotsBefore">...</span>
						<button
							v-if="currentGallery > 0 || currentGallery === galleriesArr.length"
							class="active"
							data-cursor="pointer"
						>
							{{ currentGallery + 1 }}
						</button>
						<span v-if="shouldShowDotsAfter">...</span>
						<button
							v-if="currentGallery < galleriesArr.length - 1"
							@click="goToGallery(galleriesArr.length - 1)"
							:class="{ active: currentGallery === galleriesArr.length - 1 }"
							data-cursor="pointer"
						>
							{{ sortedGalleriesArr.length }}
						</button>
					</div>
					<ArrowRightSvg
						v-if="currentGallery < galleriesArr.length - 1"
						@click="goToGallery(currentGallery + 1)"
						class="arrow-button"
						data-cursor="pointer"
					/>
				</div>
			</article>
		</div>
		<transition name="transition__popUp">
			<PopUp
				v-if="isPopUpGalleryOpen"
				:openedName="sortedGalleriesArr[currentGallery]"
				:currentPhoto="currentPhoto"
				:imagesContext="imagesContext"
				:slides="this.getGalleryImagesArray(this.sortedGalleriesArr[this.currentGallery])"
				@requestGalleryImage="getGalleryImage"
				class="popUp"
			>
			</PopUp>
		</transition>
	</section>
</template>

<script>
import PopUp from '@/components/sectionGallery/galleryPopUp/GalleryPopUp.vue'

import BrushSvg from '@/assets/components/sectionGallery/BrushSvg.vue'
import ChipSvg from '@/assets/components/sectionGallery/ChipSvg.vue'
import HammerSvg from '@/assets/components/sectionGallery/HammerSvg.vue'
import ArrowLeftSvg from '@/assets/components/sectionGallery/ArrowLeftSvg.vue'
import ArrowRightSvg from '@/assets/components/sectionGallery/ArrowRightSvg.vue'

import { galleriesArr } from './galleriesArr.js'

const katowice09_21 = require.context('/public/img/galleries/katowice09_21', false, /\.(png|jpe?g|svg|jpg)$/)
const orzesze06_22 = require.context('/public/img/galleries/orzesze06_22', false, /\.(png|jpe?g|svg|jpg)$/)
const jaworzno08_22 = require.context('/public/img/galleries/jaworzno08_22', false, /\.(png|jpe?g|svg|jpg)$/)
const jaworzno08_22second = require.context(
	'/public/img/galleries/jaworzno08_22second',
	false,
	/\.(png|jpe?g|svg|jpg)$/
)
const jaworzno01_23 = require.context('/public/img/galleries/jaworzno01_23', false, /\.(png|jpe?g|svg|jpg)$/)

export default {
	name: 'SectionGallery',
	components: {
		PopUp,
		BrushSvg,
		ChipSvg,
		HammerSvg,
		ArrowLeftSvg,
		ArrowRightSvg,
	},
	data() {
		return {
			galleriesArr,
			sortedGalleriesArr: [],
			imagesContext: {
				orzesze06_22: orzesze06_22,
				jaworzno08_22: jaworzno08_22,
				jaworzno01_23: jaworzno01_23,
				katowice09_21: katowice09_21,
				jaworzno08_22second: jaworzno08_22second,
			},
			currentGallery: 0,
			currentPhoto: 0,
			direction: 'right',
			index: 0,
			isAnimating: false,
			gradient: {
				id: 0,
				stops: [
					{ offset: 0, color: '#C6A04D' },
					{ offset: 0.229167, color: '#EFE19E' },
					{ offset: 0.5, color: '#C89F44' },
					{ offset: 0.708333, color: '#EFE19E' },
					{ offset: 1, color: '#C6A04D' },
				],
			},
		}
	},

	created() {
		this.sortedGalleriesArr = this.sortGalleriesByDate()
	},
	computed: {
		transitionEffect() {
			return this.direction === 'right' ? 'slide-out' : 'slide-in'
		},
		isMobile() {
			return this.$store.getters.isMobile
		},
		isTablet() {
			return this.$store.getters.isTablet
		},
		isDesktop() {
			return this.$store.getters.isDesktop
		},

		visiblePages() {
			const totalVisiblePages = 3
			let startPage

			if (this.currentGallery <= Math.floor(totalVisiblePages / 2) + 1) {
				startPage = 1
			} else if (this.currentGallery >= this.galleriesArr.length - Math.floor(totalVisiblePages / 2)) {
				startPage = Math.max(1, this.galleriesArr.length - totalVisiblePages + 1)
			} else {
				startPage = Math.max(1, this.currentGallery - Math.floor(totalVisiblePages / 2))
			}

			const pages = Array.from({ length: totalVisiblePages }, (_, index) => startPage + index)
			return pages
		},
		shouldShowDotsBefore() {
			return this.currentGallery > 0
		},
		shouldShowDotsAfter() {
			if (this.currentGallery === this.galleriesArr.length - 1) {
				return false
			}
			return this.galleriesArr.length > this.visiblePages.length + 1
		},
		isPopUpGalleryOpen() {
			return this.$store.getters.isPopUpGalleryOpen
		},
	},
	methods: {
		sortGalleriesByDate() {
			return [...this.galleriesArr].sort((a, b) => {
				const dateA = new Date(a.date)
				const dateB = new Date(b.date)

				return dateB - dateA
			})
		},
		incrementId(gradient, index) {
			const newGradient = { ...gradient }
			newGradient.id += index
			return newGradient
		},
		getGalleryImage(gallery, index, size) {
			const folder = this.getGalleryImagesArray(gallery)
			if (size === 'min') {
				return `/img/galleries/${gallery.imagesFolder}/min/${folder[index]}`
			}
			return `/img/galleries/${gallery.imagesFolder}/${folder[index]}`
		},
		getGalleryFolderName(gallery) {
			return gallery.imagesFolder
		},
		getGalleryImagesArray(gallery) {
			const galleryName = this.getGalleryFolderName(gallery)
			return this.imagesContext[galleryName]?.keys().map(key => key.slice(2)) || []
		},
		getDeviceName() {
			return this.isMobile ? 'mobile' : this.isTablet ? 'tablet' : this.isDesktop ? 'desktop' : ''
		},
		goToGallery(page) {
			if (!this.isAnimating) {
				this.isAnimating = true
				if (page >= 0 && page < this.galleriesArr.length) {
					let prev = this.currentGallery
					this.currentGallery = page
					this.index = page
					this.direction = prev < this.currentGallery ? 'right' : 'left'
				}
				setTimeout(() => {
					this.isAnimating = false
				}, 300)
			}
		},
		togglePopUp(index) {
			this.currentPhoto = index
			this.$store.commit('togglePopUpGallery')
		},
		startTouch(evt) {
			this.xDown = evt.touches[0].clientX
			this.yDown = evt.touches[0].clientY
		},
		moveTouch(evt) {
			if (!this.xDown || !this.yDown) {
				return
			}

			let xUp = evt.touches[0].clientX
			let yUp = evt.touches[0].clientY

			let xDiff = this.xDown - xUp
			let yDiff = this.yDown - yUp

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				if (xDiff > 0) {
					this.goToGallery(this.currentGallery + 1)
				} else {
					this.goToGallery(this.currentGallery - 1)
				}
			}
			this.xDown = null
			this.yDown = null
		},
		endTouch() {
			this.xDown = null
			this.yDown = null
		},
	},
}
</script>
<style lang="scss" scoped>
section {
	@include basic-section(none);
	@include bgPrimary-change;
	padding-top: 70px;
	padding-bottom: 50px;
}
.overlay {
	@include overlay;
}
.sectionGallery {
	@include center-margin;
	position: relative;
	width: 340px;

	.sectionGallery__header {
		p {
			margin-top: 20px;
		}
	}

	.sectionGallery__gallery {
		width: 360px;
		margin-top: 50px;
		@include carouselAnimationGallery;

		.gallery__wrapper {
			position: relative;
			height: 600px;
			width: 370px;
			overflow: hidden;

			.gallery__content {
				width: 95%;
				@include position-absolute(0, 0, 0, 0);
				@include flex(column, none, center);

				.gallery__mainImg {
					height: 300px;
					width: 300px;
					object-fit: contain;
				}

				.gallery__header {
					position: relative;
					margin-top: 80px;
					width: 90%;

					&::before {
						@include position-pseudoClass(-30px, none, none, none, 5px, 100%, none);
						background: $decoration-color-primary;
					}
					h3 {
						font-size: 24px;
						font-weight: 600;
					}
					p {
						margin-top: 20px;
						min-height: 80px;
					}
				}

				.gallery__addition {
					position: relative;
					width: 90%;
					margin-top: 40px;
					@include flex(row, center, space-between);

					&::before {
						@include position-pseudoClass(none, 45%, none, none, 100%, 1px, $text-color-secondary);
					}
					.gallery__icons {
						& > * {
							margin-right: 20px;
						}
					}

					p {
						font-size: 24px;
					}
				}
			}

			.li__additionsImgs {
				margin-top: -50px;

				img {
					height: 100px;
					width: 100px;
					margin-left: 10px;
				}
			}
		}
		.gallery__nav {
			@include center-margin;
			width: 200px;
			margin-top: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;

			.navBar__pages {
				display: flex;
				gap: 5px;
				align-items: center;
			}

			.navBar__pages button {
				background: none;
				border: none;
				color: white;
			}
			.navBar__pages button,
			.arrow-button {
				transition: filter 0.3s ease;
			}
			.navBar__pages button:hover,
			.arrow-button:hover {
				text-decoration: underline;
				filter: brightness(80%);
			}
		}
	}
}

.transition__popUp {
	@include slideInOutAnimation(0.2s, ease-out, cubic-bezier(1, 0.5, 0.8, 1));
}
.popUp {
	@include popUp-basic;
	@include flex(row, center, center);
	height: 400px;
	background-color: $background-color-tertiary;
}

.active {
	text-decoration: underline;
}
@media (max-width: 1023px) {
	.gallery__content {
		@include flex(column, none, center);

		img {
			order: 1;
		}
		.gallery__header {
			order: 3;
		}
		.gallery__addition {
			order: 4;
		}

		.li__additionsImgs {
			order: 2;
		}
	}
}
@media (min-width: 1024px) {
	.sectionGallery {
		width: 930px;

		.sectionGallery__gallery {
			width: 100%;
			.gallery__wrapper {
				height: 460px;
				width: 100%;

				.gallery__content {
					width: 100%;
					height: 460px;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-content: space-between;

					.gallery__mainImg {
						height: 460px;
						width: 475px;
					}
					.gallery__header {
						margin-top: 30px;
						width: 410px;

						h3,
						p {
							width: 380px;
							margin-left: 40px;
						}
					}
					.gallery__addition {
						width: 380px;
						margin-top: 50px;
						margin-left: 30px;
					}
				}
				.li__additionsImgs {
					margin-left: -10px;
					margin-top: auto;
					img {
						height: 200px;
						width: 200px;
					}
					img:first-child {
						margin-left: 0;
					}

					img:nth-child(2) {
						margin-left: 20px;
					}
				}
			}
		}
	}
	.popUp {
		height: 600px;
		width: 700px;
	}
}

@media (min-width: 1500px) {
	.sectionGallery {
		width: 1220px;

		.sectionGallery__gallery {
			.gallery__wrapper {
				width: 1270px;
				height: 540px;
				.gallery__content {
					@include center-margin;
					height: 100%;
					.gallery__mainImg {
						height: 540px;
						width: 570px;
					}

					.gallery__header {
						margin-top: 50px;
						width: 470px;
						margin-left: auto;
						margin-right: 10px;

						&::before {
							top: -50px;
						}

						h3,
						p {
							width: 470px;
							margin-left: 10px;
						}

						h3 {
							font-size: 36px;
						}
					}
					.gallery__addition {
						width: 460px;
						margin-left: auto;
						margin-right: 10px;
					}
				}
				.li__additionsImgs {
					margin-right: 10px;
					img {
						margin-left: 20px;
					}
				}
			}
		}
	}
	.popUp {
		height: 800px;
		width: 1000px;
	}
}
</style>
