<template>
	<svg width="26" height="27" viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.7895 6.2916e-09C18.8366 -5.75393e-05 19.8442 0.394639 20.606 1.10333C21.3679 1.81203 21.8265 2.78114 21.8879 3.8124L21.8947 4.05C22.9835 4.05 24.0277 4.4767 24.7976 5.23622C25.5675 5.99574 26 7.02587 26 8.1C26.0001 10.1957 25.1768 12.2099 23.7032 13.7193C22.2295 15.2288 20.2202 16.116 18.0974 16.1946L17.7895 16.2H12.3158L12.5211 16.2068C13.1727 16.2551 13.7852 16.5318 14.248 16.9869C14.7108 17.4419 14.9933 18.0454 15.0444 18.6881L15.0526 18.9V24.3C15.0529 24.9812 14.7921 25.6373 14.3226 26.1367C13.8531 26.6362 13.2096 26.9422 12.5211 26.9933L12.3158 27H9.57895C8.88847 27.0002 8.22344 26.743 7.71714 26.2798C7.21085 25.8166 6.90073 25.1818 6.84895 24.5025L6.84211 24.3V18.9C6.84189 18.2188 7.10266 17.5627 7.57215 17.0633C8.04165 16.5638 8.68516 16.2578 9.37368 16.2068L9.57895 16.2V14.85C9.57899 14.5193 9.70205 14.2002 9.92477 13.9531C10.1475 13.706 10.4544 13.5481 10.7873 13.5094L10.9474 13.5H17.7895C19.2412 13.5 20.6334 12.9311 21.66 11.9184C22.6865 10.9057 23.2632 9.53217 23.2632 8.1C23.2631 7.76934 23.1401 7.4502 22.9173 7.2031C22.6946 6.956 22.3877 6.79814 22.0548 6.75945L21.8947 6.75L21.8879 6.9876C21.829 7.97795 21.4036 8.91251 20.6926 9.614C19.9815 10.3155 19.0342 10.7351 18.0303 10.7933L17.7895 10.8H4.10526C3.05813 10.8001 2.05056 10.4054 1.2887 9.69667C0.526833 8.98797 0.0682739 8.01886 0.00684227 6.9876L6.37745e-09 6.75V4.05C-5.83245e-05 3.01697 0.400024 2.02295 1.11839 1.27135C1.83675 0.519741 2.81909 0.0673549 3.86442 0.00675016L4.10526 6.2916e-09H17.7895Z"
			:fill="'url(#' + gradient.id + ')'"
		/>
		<defs>
			<linearGradient :id="gradient.id" x1="-8.421e-07" y1="-1.5" x2="17.5" y2="24" gradientUnits="userSpaceOnUse">
				<stop v-for="(stop, index) in gradient.stops" :key="index" :offset="stop.offset" :stop-color="stop.color" />
			</linearGradient>
		</defs>
	</svg>
</template>

<script>
export default {
	props: ['gradient'],
	name: 'BrushSvg',
}
</script>
