<template>
	<section>
		<div class="sectionHome">
			<div class="sectionHome__header">
				<header>
					<h1 id="HeaderSectionHome">Twój Partner w Realizacji Projektów Budowlanych</h1>
					<p>
						Kompleksowo obsługujemy inwestycje budowlane, zarówno te prywatne jak i te w sektorze publicznym,
						rozpoczynając od projektu, przez wykonywanie prac budowlanych a kończąc na montażu mebli na wymiar.
					</p>
				</header>
				<div class="header__btns">
					<button class="btns__btn btns__btn--contact" @click="navigateToSection('SectionContact')">KONTAKT</button>
					<button class="btns__btn btns__btn--offer" @click="navigateToSection('SectionOffer')">OFERTA</button>
				</div>
			</div>
			<div class="sectionHome__img">
				<img :src="getImagePath('bathroom')" alt="Bathroom Photo" />
			</div>
			<article class="sectionHome__article">
				<div class="article sectionHome__article--left">
					<h3><StarsSvg></StarsSvg>Doskonała organizacja</h3>
					<p>
						- jest zdecydowanie naszym wyróżnikiem. Pozwala na idealne zgranie poszczególnych etapów budowy lub remontu
						w celu uzyskania maksymalnego efektu w możliwie krótkim czasie.
					</p>
				</div>
				<div class="article sectionHome__article--right">
					<h3><OkSvg></OkSvg>Współprace</h3>
					<p>
						- z architektami, firmami monterskimi, specjalistycznymi firmami branżowymi pozwalają na dostarczenie
						kompleksowej i kompletnej usługi na poziomie rzadko spotykanym na rynku.
					</p>
				</div>
			</article>
		</div>
	</section>
</template>
<script>
import OkSvg from '@/assets/components/sectionHome/OkSvg.vue'
import StarsSvg from '@/assets/components/sectionHome/StarsSvg.vue'
export default {
	name: 'SectionHome',
	components: { OkSvg, StarsSvg },
	computed: {},
	methods: {
		getImagePath(name) {
			const fileName = this.$store.getters.getImageFileNameByDevice(name)
			return `/img/${fileName}`
		},
		navigateToSection(sectionName) {
			this.$store.commit('navigateToSection', sectionName)
		},
	},
}
</script>

<style lang="scss" scoped>
section {
	@include basic-section($background-color-primary);
}
.sectionHome {
	@include center-margin;
	position: relative;
	padding-top: 120px;
	width: 340px;

	.sectionHome__header {
		color: $background-color-tertiary;

		P {
			font-weight: 500;
		}
	}

	.sectionHome__img {
		font-size: 0;
	}

	.btns__btn {
		&--contact {
			@include button-dark;
		}
		&--offer {
			@include button-bright;
		}
	}
	.sectionHome__article {
		margin-top: 60px;

		.article {
			height: 255px;
			width: 255px;
			padding: 15px 25px 25px 25px;
			position: relative;

			h3 {
				@include flex(none, center, none);
				gap: 5px;
				font-size: 16px;
				font-weight: 600;
			}

			p {
				font-family: 'Inter';
				margin-top: 20px;
				font-size: 14px;
				line-height: 1.3;
				letter-spacing: 0.5px;
			}
		}
		&--left {
			background-color: $background-color-quaternary;
			color: $text-color-primary;

			&::before {
				@include position-pseudoClass(0px, none, none, -15px, 100%, 6px, $background-color-quaternary);
			}
		}
		&--right {
			@include bgPrimary-change;

			&::after {
				@include position-pseudoClass(none, -15px, 0px, none, 50%, 6px, none);
				background: linear-gradient(to bottom, #c6a04d 0%, #efe09d 25%, #c89f44 50%, #efe19e 75%, #c59e4b 100%);
			}
		}
	}
}

@media (max-width: 1023px) {
	.sectionHome {
		@include flex(column, center, center);
		.sectionHome__header {
			margin-top: 40px;
			order: 2;

			header {
				text-align: center;
				h1 {
					font-size: 28px;
				}

				p {
					margin-top: 30px;
					font-size: 16px;
					line-height: 1.3;
					letter-spacing: 0.5px;
				}
			}
			.header__btns {
				@include flex(column, center, none);

				.btns__btn {
					margin-top: 40px;
				}
			}
		}
		.sectionHome__img {
			order: 1;
		}
		.sectionHome__article {
			order: 3;

			.sectionHome__article--right {
				margin-top: 40px;
			}
		}
	}
}
@media (min-width: 1024px) {
	.sectionHome {
		@include flex(row, none, space-between);
		flex-wrap: wrap;
		width: 920px;
		padding-top: 140px;

		.sectionHome__header {
			width: 430px;

			header {
				h1 {
					font-size: 40px;
				}

				p {
					margin-top: 40px;
					line-height: 1.4;
					letter-spacing: 0.5px;
					text-align: justify;
				}
			}

			.header__btns {
				@include flex(row, none, space-between);
				margin-top: 60px;

				.btns__btn--offer {
					margin-right: 40px;
				}
			}
		}

		.sectionHome__article {
			@include flex(none, none, none);
			margin-top: -50px;
			margin-left: auto;
			margin-right: 35px;

			&--left {
				margin-right: 15px;
			}

			&--right::after {
				right: -25px;
				width: 10px;
			}
		}
	}
}
@media (min-width: 1024px) and (max-width: 1499px) {
	.sectionHome__article--left {
		&::after {
			@include position-pseudoClass(0px, none, none, -250px, 100%, 5px, $background-color-quaternary);
		}
	}
}
@media (min-width: 1500px) {
	.sectionHome {
		width: 1300px;

		.sectionHome__header {
			width: 550px;
			margin-top: 60px;
			margin-left: 70px;

			header {
				h1 {
					font-size: 56px;
				}

				p {
					margin-top: 60px;
				}
			}
			.header__btns {
				margin-top: 100px;

				.btns__btn--offer {
					margin-right: 100px;
				}
			}
		}
	}
}
</style>
