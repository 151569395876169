<template>
	<section>
		<img src="@/assets/additions/logoDark.png" class="navBar__logo" alt="The Company's logo" />
		<nav>
			<ul v-if="!isMobile" class="tablet-desktop__ul">
				<li
					v-for="section in navbarLinksArray"
					:key="section.sectionName"
					@click="navigateToSection(section.sectionName)"
					:class="{ activeNavBarTablet: isSectionActive(section.sectionName) }"
				>
					{{ section.link }}
				</li>
			</ul>
			<div v-if="isMobile" class="navBar__mobile">
				<HamburgerIcon class="navBar__hamburger-icon" @click="toggleMenu" />
				<transition name="navBar__popUp">
					<ul v-if="isPopUpNavOpen" class="mobile__ul">
						<li
							v-for="section in navbarLinksArray"
							:key="section.sectionName"
							@click="navigateToSection(section.sectionName)"
							:class="{ activeNavBarMobile: isSectionActive(section.sectionName) }"
						>
							{{ section.link }}
						</li>
					</ul>
				</transition>
			</div>
		</nav>
	</section>
</template>

<script>
import HamburgerIcon from '@/assets/components/navBar/HamburgerSvg.vue'
export default {
	name: 'NavBar',
	components: {
		HamburgerIcon,
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		navbarLinksArray() {
			return this.$store.state.navbarLinks.map(section => ({
				sectionName: section.name,
				link: section.pl,
			}))
		},
		getCurrentSection() {
			return this.$store.getters.getCurrentSection
		},
		isPopUpNavOpen() {
			return this.$store.getters.isPopUpNavOpen
		},
	},
	methods: {
		isSectionActive(sectionName) {
			const isActive = sectionName === this.getCurrentSection
			return isActive
		},
		navigateToSection(sectionName) {
			this.$store.commit('navigateToSection', sectionName)
		},
		toggleMenu() {
			this.$store.commit('togglePopUpNav')
		},
	},
}
</script>

<style lang="scss" scoped>
section {
	@include flex(row, center, center);
	position: fixed;
	width: 100%;
	padding: 10px 0;
	background-color: $background-color-primary;
	display: flex;
	z-index: 5;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

	ul {
		li {
			height: 50px;
			color: $text-color-primary;
			font-size: 16px;
			cursor: pointer;
		}
	}
}

.activeNavBarMobile {
	font-weight: 800;
}
.activeNavBarTablet {
	border-top: 1px solid $text-color-primary;
	border-bottom: 1px solid $text-color-primary;
}
@media (max-width: 1023px) {
	.navBar__mobile {
		position: relative;
		margin-left: 130px;
		font-size: 0px;

		.navBar__hamburger-icon {
			cursor: pointer;
		}

		.navBar__popUp-enter-active {
			transition: all 0.2s ease-out;
		}

		.navBar__popUp-leave-active {
			transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
		}

		.navBar__popUp-enter-from,
		.navBar__popUp-leave-to {
			transform: translateY(-10%);
			opacity: 0;
		}
		.mobile__ul {
			@include position-absolute(60px, -10px, none, none);
			background-color: $background-color-secondary;
			padding: 20px 10px;

			li {
				@include flex(none, center, none);
				width: 340px;
				padding-left: 10px;
				border-top: 1px solid black;
			}

			li:last-child {
				border-bottom: 1px solid black;
			}
			&::before {
				@include position-pseudoClass(-15px, 15px, none, none, none, none, none);
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 15px solid $background-color-secondary;
			}
		}
	}
}
@media (min-width: 1024px) {
	section {

		.tablet-desktop__ul {
			@include flex(row, center, center);

			li {
				@include flex(none, center, none);
				margin-left: 70px;

				&:first-child {
					margin-left: 85px;
				}

				&:last-child {
					height: 55px;
					width: 130px;
					margin-left: 115px;
					background-color: $background-color-tertiary;
					color: $text-color-secondary;
					justify-content: center;
				}
			}
		}
	}
}
@media (min-width: 1500px) {
	section {
		padding: 25px 0;
		.tablet-desktop__ul {
			li:first-child {
				margin-left: 360px;
			}

			&:last-child {
				margin-left: 60px;
			}
		}
	}
}
</style>
