<template>
	<NavBar></NavBar>
	<main>
		<div class="decorations">
			<div class="decorations__decoration decorations__decoration--left"></div>
			<div class="decorations__decoration decorations__decoration--right"></div>
		</div>
		<SectionHome></SectionHome>
		<SectionAbout></SectionAbout>
		<SectionOffer></SectionOffer>
		<SectionGallery></SectionGallery>
		<SectionContact></SectionContact>
	</main>
	<Footer></Footer>
</template>

<script>
import NavBar from './components/navBar/NavBar.vue'
import SectionHome from './components/sectionHome/SectionHome.vue'
import SectionAbout from './components/sectionAbout/SectionAbout.vue'
import SectionOffer from './components/sectionOffer/SectionOffer.vue'
import SectionContact from './components/sectionContact/SectionContact.vue'
import SectionGallery from './components/sectionGallery/SectionGallery.vue'
import Footer from './components/footer/Footer.vue'

export default {
	name: 'App',
	components: {
		NavBar,
		SectionHome,
		SectionAbout,
		SectionOffer,
		SectionContact,
		SectionGallery,
		Footer,
	},
}
</script>
<style lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
main {
	height: 100%;
	position: relative;
}

.decorations {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	width: 340px;
	height: 100%;

	&__decoration {
		border-radius: 100px;
		width: 200px;

		&--right {
			height: 100%;
			margin-top: 180px;
			margin-left: auto;
			background-size: cover;
			background-image: url('./assets/additions/barDecoration.png');
		}
	}
}
@media (min-width: 1024px) {
	.decorations {
		@include flex(row, none, space-between);
		width: 920px;

		&__decoration {
			&--left {
				margin-top: 120px;
				height: 1100px;
				margin-left: 60px;
				background-image: url('./assets/additions/barDecoration.png');
			}
		}
	}
}

@media (min-width: 1500px) {
	.decorations {
		width: 1280px;
		margin-left: 10px;

		&__decoration--left {
			margin-top: 150px;
			margin-left: 0px;
		}
	}
}
</style>
