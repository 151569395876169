<template>
	<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_58_112)">
			<path d="M3.95837 9.5H15.0417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			<path
				d="M3.95837 9.5L7.12504 12.6667"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.95837 9.49992L7.12504 6.33325"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_58_112">
				<rect width="19" height="19" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'ArrowLeftSvg',
	computed: {},
}
</script>
