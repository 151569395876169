<template>
	<svg width="20" height="20" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line
			y1="-0.5"
			x2="28.2843"
			y2="-0.5"
			transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 1 20)"
			:stroke="calculatedStrokeColor"
		/>
		<line x1="20.6464" y1="20.3536" x2="0.646447" y2="0.353554" :stroke="calculatedStrokeColor" />
	</svg>
</template>

<script>
export default {
	name: 'CrossSvg',
	props: {
		strokeColor: String,
	},
	data() {
		return {
			defaultStrokeColor: '#242e51',
		}
	},
	computed: {
		calculatedStrokeColor() {
			return this.strokeColor || this.defaultStrokeColor
		},
	},
}
</script>
