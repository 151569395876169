<template>
	<section>
		<div class="sectionContact">
			<div class="sectionContact__header">
				<header>
					<h2 id="HeaderSectionContact">Skontaktuj się z nami!</h2>
					<p>Zapraszamy do kontaktu w celu uzyskania bezpłatnego obmiaru i wyceny!</p>
				</header>
			</div>
			<article>
				<div class="sectionContact__form">
					<form @submit.prevent="submitForm" class="form">
						<label class="form__label form__label--firstname">
							<div class="input-wrapper" :class="{ error: !isNameValid && firstNameClicked }">
								<div class="icon-container">
									<HumanSvg class="icon"></HumanSvg>
								</div>
								<input
									type="text"
									id="firstName"
									v-model="formData.firstName"
									@focus=";(firstNameClicked = true), isNameValid, setButton()"
									required
									placeholder="Imię..."
								/>
							</div>
						</label>
						<label class="form__label form__label--lastName">
							<div class="input-wrapper" :class="{ error: !isLastNameValid && lastNameClicked }">
								<div class="icon-container">
									<HumanSvg class="icon"></HumanSvg>
								</div>
								<input
									type="text"
									id="lastName"
									v-model="formData.lastName"
									@focus=";(lastNameClicked = true), isLastNameValid, setButton()"
									required
									placeholder="Nazwisko..."
								/>
							</div>
						</label>
						<label class="form__label form__label--email">
							<div class="input-wrapper" :class="{ error: !isEmailValid && emailClicked }">
								<div class="icon-container">
									<AtSvg class="icon"></AtSvg>
								</div>
								<input
									type="email"
									id="email"
									v-model="formData.email"
									@focus=";(emailClicked = true), isEmailValid, setButton()"
									required
									placeholder="Email..."
								/>
							</div>
						</label>

						<label class="form__label form__label--message">
							<div class="input-wrapper input-wrapper--textarea" :class="{ error: !isMessageValid && messageClicked }">
								<div class="icon-container">
									<PencilSvg class="icon"></PencilSvg>
								</div>
								<textarea
									id="message"
									maxlength="250"
									v-model="formData.message"
									@focus=";(messageClicked = true), isMessageValid, setButton()"
									required
									placeholder="Treść..."
								/>
							</div>
						</label>
						<div class="form__button--container">
							<button :disabled="!isValid" type="submit" :class="{ 'disabled-button': !isValid }">
								{{ this.textButton }}
							</button>
						</div>
					</form>
				</div>
				<div v-if="!isMobile" class="sectionContact__image">
					<img :src="getImagePath('worker')" alt="Construction Worker" />
				</div>
			</article>
		</div>
	</section>
</template>

<script>
import apiBaseURL from '../../shared/api.js'
import HumanSvg from '@/assets/components/sectionContact/HumanSvg.vue'
import PencilSvg from '@/assets/components/sectionContact/PencilSvg.vue'
import AtSvg from '@/assets/components/sectionContact/AtSvg.vue'
export default {
	name: 'SectionContact',
	components: {
		HumanSvg,
		PencilSvg,
		AtSvg,
	},
	data() {
		return {
			formData: {
				firstName: '',
				lastName: '',
				email: '',
				message: '',
			},
			firstNameClicked: false,
			lastNameClicked: false,
			emailClicked: false,
			messageClicked: false,
			textButton: 'Wyślij',
		}
	},
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		isValid() {
			return this.isNameValid && this.isLastNameValid && this.isEmailValid && this.isMessageValid
		},
		isNameValid() {
			const nameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{2,}$/
			return nameRegex.test(this.formData.firstName)
		},
		isLastNameValid() {
			const lastNameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{2,}([ -][a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]{2,})?$/
			return lastNameRegex.test(this.formData.lastName)
		},
		isEmailValid() {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			return emailRegex.test(this.formData.email)
		},
		isMessageValid() {
			return this.formData.message.length >= 10
		},
	},
	methods: {
		getImagePath(name) {
			const fileName = this.$store.getters.getImageFileNameByDevice(name)
			return require(`@/assets/components/sectionContact/${fileName}`)
		},

		submitForm() {
			const formDataObject = {
				name: this.formData.firstName,
				surname: this.formData.lastName,
				email: this.formData.email,
				message: this.formData.message,
			}

			this.textButton = 'Wysłano'
			this.resetFormData()
			apiBaseURL
				.post('', formDataObject)
				.then(response => {
					console.log(response)
				})
				.catch(error => {
					console.error('Błąd podczas wysyłania żądania:', error)
				})
		},

		resetFormData() {
			this.formData = {
				firstName: '',
				lastName: '',
				email: '',
				message: '',
			}
			;(this.firstNameClicked = false),
				(this.lastNameClicked = false),
				(this.emailClicked = false),
				(this.messageClicked = false)
		},
		setButton() {
			if (this.textButton !== 'Wyślij') {
				this.textButton = 'Wyślij'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
section {
	@include basic-section($background-color-primary);
}

.sectionContact {
	@include center-margin;
	width: 340px;
	position: relative;
	margin-top: 70px;

	.sectionContact__header {
		@include header-section($text-color-primary);
		p {
			margin-top: 20px;
		}
	}
	article {
		.sectionContact__form {
			@include flex(column, none, none);

			form {
				.input-wrapper {
					height: 45px;
					display: flex;
					align-items: center;
					background-color: $background-color-quinary;
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
					border-radius: 5px;
					margin-bottom: 20px;
					.icon-container {
						@include flex(row, center, center);
						.icon {
							width: 60px;
							border-right: 1px solid #bababa;
						}
					}
					::placeholder {
						color: $text-color-tertiary;
					}
					input {
						height: 43px;
					}
					input,
					textarea {
						width: 75%;
						font-size: 16px;
						color: #606060;
						background-color: $background-color-quinary;
						margin-left: 20px;
						border: none;
						resize: none;
						font-family: inherit;
					}

					&--textarea {
						position: relative;
						padding-top: 10px;
						height: 230px;
						align-items: flex-start;
					}
					textarea {
						height: 100%;
					}
					input:focus,
					textarea:focus {
						outline: none;
					}
				}

				.input-wrapper--textarea::after {
					@include position-pseudoClass(none, 20px, 10px, none, 3px, 300px, $text-color-tertiary);
				}
				.error {
					border: 1px solid red !important;
				}
			}
			.form__button--container {
				text-align: right;

				button {
					@include button-dark;
					margin: 35px 0 80px 0;

					&:disabled,
					&.disabled-button {
						opacity: 0.5;
						cursor: not-allowed;
					}
					z-index: 5;
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.sectionContact__form {
		margin-top: 60px;
	}
}

@media (min-width: 1024px) {
	.sectionContact {
		width: 920px;
		margin-top: 60px;

		.sectionContact__header p {
			width: auto;
		}

		article {
			@include flex(row, none, space-between);
			margin-top: 60px;

			.sectionContact__form {
				width: 510px;

				form {
					.input-wrapper {
						height: 65px;
						margin-bottom: 30px;
						.icon-container {
							.icon {
								width: 80px;
							}
						}
						input {
							height: 63px;
						}

						&--textarea {
							height: 340px;
							padding-top: 20px;
						}

						textarea {
							padding-top: 5px;
						}
					}
					.input-wrapper--textarea::after {
						height: 4px;
						width: 470px;
						bottom: 30px;
					}
				}

				button {
					margin-bottom: 110px;
				}
			}

			.sectionContact__image {
				position: relative;
				font-size: 0px;
				height: 100%;

				img {
					height: 630px;
				}
			}

			.sectionContact__image::before {
				@include position-pseudoClass(none, 0px, 0px, -65px, 100%, 10px, none);
				background: linear-gradient(to bottom, #c6a04d 0%, #efe09d 25%, #c89f44 50%, #efe19e 75%, #c59e4b 100%);
			}
		}
	}
}

@media (min-width: 1500px) {
	.sectionContact {
		width: 1220px;
		transform: translateX(40px);
		article {
			.sectionContact__image::before {
				left: -110px;
				width: 15px;
			}
		}
	}
}
</style>
