<template>
	<div class="wrapper">
		<div class="containerSection">
			<img :src="getImagePath(offerData.image)" alt="Photo of offer" />
			<h3>
				<component :is="getIconComponent(offerData.icon)" class="icon" />
				{{ offerData.name }}
			</h3>
			<p>{{ offerData.text }}</p>
		</div>
	</div>
</template>

<script>
import ConsultingSvg from '@/assets/components/sectionOffer/ConsultingSvg.vue'
import RenovationWorksSvg from '@/assets/components/sectionOffer/RenovationWorksSvg.vue'
import InstallationWorksSvg from '@/assets/components/sectionOffer/InstallationWorksSvg.vue'
import BuildingWorksSvg from '@/assets/components/sectionOffer/BuildingWorksSvg.vue'
import SprayMethodsSvg from '@/assets/components/sectionOffer/SprayMethodsSvg.vue'

export default {
	name: 'OfferItem',
	components: {
		ConsultingSvg,
		RenovationWorksSvg,
		InstallationWorksSvg,
		BuildingWorksSvg,
		SprayMethodsSvg,
	},
	props: {
		offerData: Object,
	},
	methods: {
		getImagePath(fileName) {
			return `/img/offer/${fileName}`
		},
		getIconComponent(icon) {
			const context = require.context('@/assets/components/sectionOffer/', false, /\.vue$/)
			const component = context(`./${icon}.vue`).default
			return component
		},
	},
}
</script>
<style lang="scss" scoped>
.overlay {
	@include overlay;
}
img {
	height: 340px;
	width: 340px;
	font-size: 0px;
	box-shadow: 6px 14px 7px 0 rgba(0, 0, 0, 0.25);
}
h3 {
	position: relative;
	display: flex;
	align-items: center;
	font-size: 24px;

	.icon {
		margin-right: 25px;
	}
	&::after {
		@include position-pseudoClass(none, none, -35px, none, 5px, 100%, none);
		background: linear-gradient(to right, #c59e4b, #efe19e, #c89f44, #efe09d, #c6a04d);
	}
}

p {
	margin-top: 50px;
}
@media (max-width: 1023px) {
	h3 {
		margin-top: 40px;
	}
}
@media (min-width: 1024px) {
	.containerSection {
		@include flex(column, none, none);
		flex-wrap: wrap;
		height: 305px;

		> *:not(:first-child) {
			width: 580px;
			margin-left: 45px;
		}
		img {
			height: 305px;
			width: 305px;
		}
		h3::after {
			bottom: -45px;
		}
		p {
			margin-top: 60px;
		}
	}
}
@media (min-width: 1500px) {
	.containerSection {
		> *:not(:first-child) {
			width: 950px;
		}
	}
}
</style>
