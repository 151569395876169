<template>
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_45_20)">
			<path
				d="M6.66667 10H33.3333"
				stroke="#232E51"
				stroke-width="3.33333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.6667 20H33.3333"
				stroke="#232E51"
				stroke-width="3.33333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.6667 30H33.3333"
				stroke="#232E51"
				stroke-width="3.33333"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_45_20">
				<rect width="40" height="40" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'HamburgerSvg',
	computed: {},
}
</script>
